.box-post {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__visual {
        position: relative;
        padding-top: 100%;
        width: 100%;
        flex-grow: 1;
        display: block;
        border-radius: 16px;
        overflow: hidden;

        @include media('≥phone') {
            padding: 0;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            transform: scale(1);
            transition: transform $animation-speed;

            @include media('≥phone') {
                position: static;
            }
        }
    }

    &__title {
        padding: 8px 0 0;
    }

    h3 {
        margin: 0;
        font-size: 16px;
        line-height: calc(24/16);
        letter-spacing: 0.03em;
        font-weight: $medium;
        color: $gray-900;

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    a.box-post__visual {
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}