@use '../abstracts/variables' as *;

.bonuses {
    padding: 11px 16px 7px;
    font-weight: $medium;
    color: $white;
    background: $violet;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;

    &__title {
        display: block;
        margin: 0 0 10px;
        font-weight: $bold;
        font-size: 18px;
        line-height: 20px;
    }

    &__time {
        letter-spacing: 0.03em;
    }
}
