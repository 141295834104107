@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.popup-holder {
    position: relative;
    overflow: hidden;
    height: 0;
}

.fancybox-is-open .fancybox-bg {
    opacity: .7;
}

.fancybox-bg {
    background: #615E69;
}

.modal-block {
    width: 100%;
    max-width: 346px;
    border-radius: 16px;
    padding: 80px 16px 16px;

    @include media('≥tablet') {
        max-width: 376px;
        padding: 80px 16px 24px;
    }

    .fancybox-close-small {
        display: none;
    }

    &--lg {
        max-width: 762px;
    }
}

.modal-close {
    position: absolute;
    top: 25px;
    left: 25px;
    font-size: 24px;
    line-height: 1;
    color: $gray-900;

    &:hover {
        color: $violet;
    }
}

.modal-container {
    font-size: 14px;
    line-height: 24px;
    color: $gray-800;

    .btn {
        display: block;
        width: 100%;
        padding: 15px 16px;
    }

    .btn--sm {
        padding: 7px 16px;
    }
}

.modal-content {
    padding: 0 0 4px;

    h2 {
        margin: 0 0 7px;
        font-size: 24px;
        line-height: 32px;
        color: $gray-900;
    }
}

.modal-info {
    padding: 15px 0 40px;
    border-top: 1px solid #DFDEE0;

    &--add {
        padding: 15px 0;
    }

    &__row {
        display: flex;
        justify-content: space-between;

        +.modal-info__row {
            margin-top: 8px;
        }
    }

    &__text {
        margin: 0 10px 0 0;
    }

    &__value {
        font-size: 16px;
        font-weight: $medium;
    }

    .status-1 {
        color: $green;
    }

    .status-2 {
        color: $red;
    }
}

.input-wrap {

    input,
    select {
        margin-bottom: 24px;
    }
}

.modal-text {
    padding: 15px 0 0;
    border-top: 1px solid #DFDEE0;

    p {
        &:last-child {
            margin: 0;
        }
    }
}

.modal-buttons {
    padding: 20px 0 0;

    .btn {
        +.btn {
            margin-top: 16px;
        }
    }
}
