@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.social-networks {
    margin: -6px;
    padding: 17px 0 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-size: 22px;
    line-height: 1;

    @include media('≥tablet') {
        padding: 17px 0;
    }

    >li {
        padding: 6px;
    }

    a {
        color: $gray-900;
        transition: all $animation-speed;

        &:hover {
            color: $violet;
        }
    }
}
