.num {
    display: inline-block;
    vertical-align: text-top;
    padding: 2px;
    height: 12px;
    min-width: 12px;
    border-radius: 6px;
    background: $violet;
    color: $white;
    font-weight: $normal;
    font-size: 8px;
    line-height: 1;
    text-align: center;
}