@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../include-media/include-media' as *;

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site
// -----------------------------------------------------------------------------

@use '../abstracts/variables';

.header {
    position: relative;
    background: variables.$white;
    z-index: 99;

    &__panel {
        padding-top: 12px;
        padding-bottom: 12px;
        display: flex;
        justify-content: space-between;

        @include media('≥desktop') {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &__panel-box-left {
        display: flex;
    }

    &__panel-box-right {
        display: flex;
    }

    &__midle {
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-top: 5px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: $shadow-01;
        border-radius: 99px;
        border-bottom: 1px solid $gray-200;
        width: calc(100% - 32px);

        @include media('≥tablet') {
            width: auto;
            margin-top: 0;
            margin-bottom: 10px;
            padding-top: 0;
            padding-bottom: 0;
            box-shadow: none;
        }

        &--add {
            @include media('<tablet') {
                display: none;
            }
        }
    }

    &__search-header {
        @include media('≥tablet') {
            width: 260px;
            position: absolute;
            top: -52px;
            left: 50%;
            transform: translateX(-50%);
        }

        @include media('≥desktop') {
            top: -60px;
            width: 304px;
        }
    }

    &__filter-opener {
        @include media('≥tablet') {
            position: absolute;
            right: 16px;
            top: calc(100% + 15px);
        }


        .container-lg & {
            @include media('≥1440px') {
                right: 87px;
            }
        }
    }

    &__logo {
        margin: -4px 0 0;

        @include media('≥tablet') {
            display: block;
            margin: -3px 10px -8px 0;
        }

        @include media('≥desktop') {
            margin: 0 17px 0 0;
        }
    }

    &__menu-box {
        position: relative;
    }

    &__hr {
        border: 0;
        border-bottom: 1px solid $gray-200;
    }
}
