.breadcrumbs-wrap {
    position: relative;
    z-index: 11;
    padding: 10px 0 19px;
    min-height: 61px;
    display: flex;
    align-items: start;
    font-size: 14px;
    line-height: 24px;
    font-weight: $normal;

    @include media('≥tablet') {
        position: static;
        overflow: auto;
        margin-right: 130px;
    }

    a {
        color: $gray-500;

        &:hover {
            color: $gray-900;
        }
    }
}

.breadcrumbs-home {
    margin: 4px 0 0 7px;
    display: flex;
    align-items: center;

    svg {
        display: block;
        margin: 0 8px 0 0;
    }
}

.breadcrumbs-opener {
    &__ico {
        position: relative;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray-200;
        color: $gray-500;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        font-size: 7px;
        line-height: 1;

        @include media('≥tablet') {
            display: none;
        }

        &:after {
            position: absolute;
            pointer-events: none;
            margin-left: 16px;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33333 9.3335C2.6 9.3335 2 8.7335 2 8.00016C2 7.26683 2.6 6.66683 3.33333 6.66683C4.06667 6.66683 4.66667 7.26683 4.66667 8.00016C4.66667 8.7335 4.06667 9.3335 3.33333 9.3335Z' stroke='%23A5A3A9' stroke-width='1.5'/%3E%3Cpath d='M12.6668 9.3335C11.9335 9.3335 11.3335 8.7335 11.3335 8.00016C11.3335 7.26683 11.9335 6.66683 12.6668 6.66683C13.4002 6.66683 14.0002 7.26683 14.0002 8.00016C14.0002 8.7335 13.4002 9.3335 12.6668 9.3335Z' stroke='%23A5A3A9' stroke-width='1.5'/%3E%3Cpath d='M7.99984 9.3335C7.2665 9.3335 6.6665 8.7335 6.6665 8.00016C6.6665 7.26683 7.2665 6.66683 7.99984 6.66683C8.73317 6.66683 9.33317 7.26683 9.33317 8.00016C9.33317 8.7335 8.73317 9.3335 7.99984 9.3335Z' stroke='%23A5A3A9' stroke-width='1.5'/%3E%3C/svg%3E");
            background-position: 50% 50%;
            background-repeat: no-repeat;
        }
    }

    // &:hover {
    //     .breadcrumbs {
    //         opacity: 1;
    //         visibility: visible;
    //     }
    // }
}

.breadcrumbs {
    margin: 0;
    padding: 24px 24px 10px;
    list-style: none;
    position: absolute;
    top: calc(100% - 13px);
    left: 0;
    right: 0;
    font-size: 16px;
    line-height: 24px;
    background: $white;
    box-shadow: 0 0 4px rgba($black, 0.04), 0 4px 8px rgba($black, 0.06);
    border-radius: 16px;
    // opacity: 0;
    // visibility: hidden;
    // transition: all $animation-speed;

    @include media('≥tablet') {
        // opacity: 1;
        // visibility: visible;
        margin: 0 0 -5px;
        padding: 0;
        position: static;
        display: flex;
        flex-wrap: wrap;
        box-shadow: none;
        border-radius: 0;
        font-size: 14px;
        line-height: 24px;
    }

    a {
        color: $gray-900;

        @include media('≥tablet') {
            color: $gray-500;
        }
    }

    >li {
        margin-bottom: 15px;

        @include media('≥tablet') {
            margin-bottom: 5px;
            white-space: nowrap;
        }

        &:before {
            @include media('≥tablet') {
                margin: 0 16px;
                content: '\e90a';
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background: $gray-200;
                color: $gray-500;
                width: 32px;
                height: 32px;
                border-radius: 8px;
                font-size: 12px;
                line-height: 1;
                font-family: $icons;
            }
        }
    }
}