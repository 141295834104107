@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.checkout-active {
    overflow: hidden;
}

.checkout-wrap {
    position: fixed;
    padding: 24px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    overflow: auto;
    background: rgba($gray-700, .7);
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all calc($animation-speed / 2);

    @include media('≥phone') {
        padding: 24px 0;
    }

    @include media('≥tablet') {
        padding: 32px;
    }

    .checkout-active & {
        opacity: 1;
        visibility: visible;
    }

    .checkout-wrap__container {
        max-width: 656px;
        padding: 0;
    }
}

.checkout-box {
    position: relative;
    background: $white;
    border-radius: 16px 16px 0 0;

    label {
        cursor: pointer;
    }

    &__content {
        padding-bottom: 88px;

        @include media('≥phone') {
            padding-bottom: 0;
        }
    }

    &__checkout-close {
        position: absolute;
        left: 24px;
        top: 16px;
        font-size: 23px;
        line-height: 1;
        color: $gray-900;
        transition: color $animation-speed;

        @include media('≥tablet') {
            left: 34px;
        }

        &:hover {
            color: $violet;
        }
    }

    &__header {
        padding: 18px 60px 50px;
        text-align: center;

        @include media('≥tablet') {
            padding: 18px 60px 58px;
        }

        h2 {
            letter-spacing: 0.03em;
            font-size: 16px;
            line-height: 20px;
            margin: 0;
            font-weight: $semibold;
        }
    }

    h3 {
        letter-spacing: 0.03em;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 15px;
        font-weight: $semibold;

        @include media('≥tablet') {
            margin: 0 0 23px;
        }
    }

    &__row {
        padding: 24px 16px 33px;
        border-bottom: 1px solid $gray-100;

        @include media('≥tablet') {
            padding: 24px 24px 40px;
        }
    }

    &__pt-0 {
        padding-top: 0;
    }

    &__footer {
        padding: 16px 24px;
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;
        background: $white;
        border-top: 1px solid $gray-100;
        text-align: right;

        @include media('≥phone') {
            border: 0;
            position: static;
        }
    }

    &__footer-columns {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__clear {
        padding: 0;
        margin: 0;
        color: $gray-900;
        background: none;
        border: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: $medium;

        &:hover {
            color: $violet;
        }
    }

    &__btn {
        height: 56px;
        max-width: 344px;
        width: 100%;
    }
}

.checkout-heading {
    padding: 0 24px;
    margin: 0 0 17px;
    font-size: 16px;
    line-height: calc(24/16);
    color: $gray-800;

    &__visual {
        margin: 0 0 16px;
        border-radius: 16px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    h2 {
        margin: 0 0 15px;
        font-size: 24px;
        line-height: calc(32/24);
        color: $gray-900;
    }

    p {
        margin: 0;
    }
}

.quantity_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.quantity {
    position: relative;
    width: 160px;
    min-width: 160px;

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
        padding-left: 45px;
        padding-right: 45px;
        text-align: center;
    }

    .quantity-button {
        position: absolute;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border: 1px solid $gray-900;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: $gray-900;
        font-size: 13px;
        line-height: 17px;
        top: 50%;
        transform: translateY(-50%);
        user-select: none;
    }

    .quantity-up {
        right: 23px;
    }

    .quantity-down {
        left: 23px;
    }
}
