.pagination-wrap {
    padding: 20px 0 40px;

    &--add {
        padding-top: 40px;
    }
}

.pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;

    >li {
        padding: 2px;

        >* {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            font-size: 16px;
            line-height: 20px;
            font-weight: $medium;
            color: $gray-900;
            border-radius: 8px;
            box-shadow: 0 0 6px rgba($black, 0.02), 0 2px 4px rgba($black, 0.08);
        }
    }

    a {
        transition: all $animation-speed;

        &:hover {
            background: $gray-100;
        }
    }

    .pagination__dot {
        box-shadow: none;
    }

    .pagination__current {
        background: $gray-100;
    }
}