.form-checkout {
    padding: 21px 0 90px;
    max-width: 688px;
    margin: 0 auto;

    @include media('≥tablet') {
        padding: 6px 0 90px;
    }

    h1 {
        margin: 0 0 23px;
        font-weight: $semibold;
        font-size: 24px;
        line-height: calc(32/24);
    }

    h2 {
        margin: 0 0 16px;
        font-weight: $semibold;
        font-size: 24px;
        line-height: calc(32/24);
        color: $gray-900;
    }

    h3 {
        margin: 0 0 23px;
        font-weight: $bold;
        font-size: 18px;
        line-height: calc(24/18);
        color: $gray-900;
    }
}

.opener-all {
    display: block;
    position: relative;
    margin: 16px 0 25px;

    @include media('≥tablet') {
        margin-top: 0;
    }

    &:after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border: 2px solid $gray-900;
        border-radius: 8px;
        content: '';
        background: transparent;
        font-family: $icons;
        color: $white;
        font-size: 8px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#opener-company-information {
    position: absolute;
    opacity: 0;

    &:checked {
        +.info-columns {
            .company-information-slide {
                display: block;
            }

            .opener-all {
                &:after {
                    content: '\e904';
                    background: $gray-900;
                }
            }
        }
    }
}

.info-columns {
    padding: 24px 0 16px;
    margin: 0 0 16px;
    border-top: 1px solid $gray-600;
    border-bottom: 1px solid $gray-600;

    @include media('≥tablet') {
        display: flex;
        justify-content: space-between;
    }

    &__col {
        @include media('≥tablet') {
            width: 47%;
        }
    }

    input {
        margin-bottom: 24px;
    }

    .company-information-slide {
        display: none;
    }
}

.choose-option {
    padding: 24px 0 24px;
    border-bottom: 1px solid $gray-600;

    h3 {
        margin-bottom: 8px;
    }

    p {
        margin: 0 0 24px;
    }
}

.choose-list {
    margin: 0;
    padding: 7px 0 0;
    list-style: none;

    >li {
        margin: 0 0 24px;
    }
}

.step-footer {
    @include media('≥tablet') {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__col {
        @include media('≥tablet') {
            width: 47%;
        }

        &:first-child {
            display: none;

            @include media('≥tablet') {
                display: block;
            }
        }
    }

    .btn--primary {
        display: block;
    }

    .total {
        margin-bottom: 23px;
    }
}

.coupon-input {
    padding: 24px 0 16px;
    margin: 0 0 40px;
    border-bottom: 1px solid $gray-600;

    label {
        color: $gray-900;
    }

    @include media('≥tablet') {
        display: flex;
        justify-content: space-between;
    }

    &__col--lg {
        @include media('≥tablet') {
            width: 66%;
        }
    }

    input {
        margin-bottom: 24px;
    }
}

.total {
    display: flex;
    justify-content: space-between;
    line-height: 24px;

    strong {
        font-weight: $bold;
        font-size: 18px;
    }
}

.button-wrap-submit {
    text-align: right;
    padding: 16px 0 1px;

    .btn {
        width: 100%;

        @include media('≥tablet') {
            max-width: 324px;
        }
    }
}

.hold-step {
    display: none;

    &.active {
        display: block;
    }
}
