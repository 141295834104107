.list-bonuses {
    margin: 0;
    padding: 16px 0 6px;
    list-style: none;

    >li {
        margin: 0 0 8px;
    }
}

.bonus-item {
    padding: 16px;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid $gray-400;
    border-radius: 16px;
    color: $gray-550;

    &__head {
        margin: 0 0 10px;
        display: flex;
        justify-content: space-between;
        font-weight: $medium;
        font-size: 16px;
        line-height: 20px;
    }

    &__time {
        color: $gray-900;
    }

    &__price {
        color: $violet;
    }

    p {
        margin: 0;
    }
}