// -----------------------------------------------------------------------------
// This file contains all styles related to the common layout of the site
// -----------------------------------------------------------------------------
[id="wrapper"] {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    background: $base-background-color;
}

main {
    flex-grow: 1
}

.container {
    width: 100%;
    max-width: $max-width-center-container;
    padding: $padding-container;
    margin: 0 auto;

    &--sm {
        max-width: 720px;
    }

    .container-lg & {
        max-width: 2540px;

        @include media('≥widescreen') {
            padding-left: 87px;
            padding-right: 87px;
        }
    }
}

.two-columns {

    @include media('≥tablet') {
        display: flex;
        align-items: flex-start;
    }

    &__col {
        &:first-child {
            @include media('≥tablet') {
                width: 280px;
                min-width: 280px;
                position: sticky;
                top: 10px;
            }
        }

        &:last-child {
            @include media('≥tablet') {
                flex-grow: 1;
                padding: 10px 0 0 24px;
                width: calc(100% - 280px);
            }
        }
    }
}

.js-tab-hidden {
    display: block !important;
    left: -9999px !important;
    position: absolute !important;
    top: -9999px !important;
}

iframe {
    display: block;
    width: 100%;
}

.mob-hidden {
    @include media('<tablet') {
        display: none !important;
    }
}

.list-cards {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media('≥tablet') {
        padding: 0 8px;
    }

    >li {
        +li {
            margin-top: 8px;
        }
    }

    .card-slider {
        &__visual {
            padding-top: 59%;
        }
    }
}

.content-page {
    margin-bottom: 50px;
}