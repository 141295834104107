@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.grid-gallery {
    margin: 0 -8px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 100%;
        padding: 8px;
        display: flex;

        @include media('≥phone') {
            width: 50%;
        }

        @include media('≥tablet') {
            width: calc(100% / 3);
        }

        @include media('≥desktop') {
            width: 25%;
        }

        @include media('≥widescreen') {
            width: calc(100% / 5);
        }

        @include media('≥xl') {
            width: calc(100% / 6);
        }

        &--w50 {
            width: 100%;

            @include media('≥tablet') {
                width: calc(100% / 3 * 2);
            }

            @include media('≥desktop') {
                width: 50%;
            }

            @include media('≥widescreen') {
                width: calc(100% / 5 * 2);
            }

            @include media('≥xl') {
                width: calc(100% / 6 * 2);
            }
        }
    }
}
