@use '../abstracts/variables' as *;

.thank-section {
    padding: 14px 0;

}

.thank-hold {
    color: $gray-900;

    h1 {
        margin: 0 0 15px;
        font-size: 24px;
        line-height: 32px;
        color: $gray-900;
    }

    h2 {
        margin: 0 0 8px;
        font-size: 18px;
        line-height: 24px;
    }
}

.thank-row {
    padding: 23px 0 40px;
    font-size: 16px;
    line-height: 24px;
    border-top: 1px solid #DFDEE0;

    &__columns {
        display: flex;
        justify-content: space-between;

        +.thank-row__columns {
            margin: 8px 0 0;
        }
    }

    &__col {
        &:last-child {
            text-align: right;
        }

        &:only-child {
            text-align: left;
        }
    }

    &__text {
        color: $gray-800;
        margin: 0 5px 0 0;
    }

    &__subvalue {
        margin: -6px 0 -4px;
        display: block;
        font-weight: $medium;
        font-size: 12px;
    }
}
