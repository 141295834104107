@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.sort-block {
    padding-bottom: 13px;

    @include media('≥tablet') {
        margin: 0 134px 0 0;
    }

    &__list {
        margin: 0 -16px;
        padding: 0;
        list-style: none;
        display: flex;
        overflow: auto;

        >li {
            width: 100%;
            padding: 0 16px 11px;
            text-align: center;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        color: $gray-700;
        transition: all $animation-speed;

        &:hover {
            color: $gray-900;

            &:after {
                width: 100%;
                visibility: visible;
            }
        }

        &:after {
            pointer-events: none;
            position: absolute;
            bottom: -10px;
            left: 50%;
            width: 0;
            height: 1px;
            content: '';
            background: currentColor;
            visibility: hidden;
            transform: translateX(-50%);
            transition: all $animation-speed;
        }
    }

    &__item-ico {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto 8px;
        font-size: 20px;
        line-height: 1;
    }

    .active {
        .sort-block__item {
            color: $gray-900;

            &:after {
                width: 100%;
                visibility: visible;
            }
        }
    }
}
