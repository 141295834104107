@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.custom-checkbox-list-button {
    margin: -4px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    >li {
        padding: 4px;
        width: 50%;
    }
}

.custom-radio-button {
    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;

        &:checked {
            +.custom-radio-button__text {
                background-color: $gray-900;
                color: $white;
                border-color: $gray-900;
            }
        }
    }

    &__text {
        display: block;
        padding: 15px 16px;
        width: 100%;
        min-height: 56px;
        color: $gray-900;
        font-size: 16px;
        line-height: 24px;
        border-radius: 16px;
        text-align: center;
        border: 1px solid $gray-600;
        background-color: $white;
        transition: all $animation-speed;
    }
}

.custom-checkbox-list {
    margin: -8px;
    padding: 0;
    list-style: none;

    @include media('≥phone') {
        display: flex;
        flex-wrap: wrap;
    }

    >li {
        padding: 8px;
        width: 100%;
        margin: 0 0 4px;

        @include media('≥phone') {
            width: 50%;
        }

        @include media('≥600px') {
            width: calc(100% / 3);
        }
    }
}

.custom-checkbox {
    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;

        &:checked {
            +.custom-checkbox__text {
                &:before {
                    content: '\e904';
                    background: $gray-900;
                    border-color: $gray-900;
                }
            }
        }
    }

    &__text {
        position: relative;
        display: block;
        padding: 0 40px 0 0;
        width: 100%;
        color: $gray-900;
        font-size: 16px;
        line-height: 24px;
        border-radius: 16px;

        @include media('≥phone') {
            padding: 0 0 0 40px;
        }

        &:before {
            position: absolute;
            right: 0;
            top: 0;
            content: '';
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid $gray-600;
            color: $white;
            width: 24px;
            height: 24px;
            border-radius: 8px;
            font-size: 10px;
            line-height: 1;
            font-family: $icons;
            transition: all $animation-speed;

            @include media('≥phone') {
                right: auto;
                left: 0;
            }
        }
    }

    &--right {
        .custom-checkbox__text {
            padding: 0 40px 0 0;

            &:before {
                left: auto;
                right: 0;
            }
        }
    }
}

.custom-checkbox-2 {
    position: relative;
    display: block;
    padding: 15px 50px 15px 16px;
    width: 100%;
    height: 56px;
    color: $gray-900;
    font-size: 16px;
    line-height: 24px;
    border-radius: 16px;
    border: 1px solid $gray-600;
    background-color: $white;

    input {
        position: absolute;
        opacity: 0;
        visibility: hidden;

        &:checked {
            +.custom-checkbox-2__text {
                &:before {
                    border-width: 6px;
                    border-color: $gray-900;
                }
            }
        }
    }

    &__text {
        &:before {
            content: '';
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid $gray-600;
            transition: border $animation-speed;
        }
    }
}

.custom-checkbox-3 {
    position: relative;
    display: block;
    padding: 15px 55px 15px 16px;
    width: 100%;
    min-height: 56px;
    color: $gray-900;
    font-size: 16px;
    line-height: 24px;
    border-radius: 16px;
    border: 1px solid $gray-600;
    background-color: $white;

    &__text {
        display: flex;
        justify-content: space-between;
    }

    &__num {
        margin-left: 15px;
    }

    input {
        position: absolute;
        opacity: 0;
        visibility: inherit;

        &:checked {
            ~.custom-checkbox-3 {
                &__text {
                    &:before {
                        border-width: 6px;
                        border-color: $gray-900;
                    }
                }

                &__slide {
                    display: block;
                }
            }
        }
    }

    &__text {
        &:before {
            content: '';
            position: absolute;
            right: 16px;
            top: 15px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            border: 2px solid $gray-600;
            transition: border $animation-speed;
        }
    }

    &__slide {
        padding: 15px 0 0;
        margin: 0 -40px 0 0;
        display: none;
        text-align: center;

        a>img {
            border-radius: 8px;
        }
    }
}
