@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.settings-section {
    padding: 5px 0 34px;

    h2 {
        margin: 0 0 14px;
        font-size: 18px;
        line-height: 24px;
        color: $gray-900;
    }

    .pagination-wrap {
        padding-top: 0;
    }

    .pagination {
        justify-content: flex-end;
    }

    &.active {
        .two-columns__col--first {
            display: none;

            @include media('≥tablet') {
                display: block;
            }
        }
    }
}

.back-button {
    display: inline-block;
    color: $gray-900;
    font-size: 18px;
    font-weight: $bold;
    line-height: 24px;
    margin-bottom: 32px;

    @include media('≥tablet') {
        display: none;
    }

    &:hover {
        color: $violet;
    }

    .icon-prev {
        font-size: 0.8em;
    }
}

.navigation-box {
    color: $gray-900;
    padding: 15px 0;

    @include media('≥tablet') {
        padding: 15px;
        margin-bottom: 24px;
        border-radius: 16px;
        border: 1px solid $gray-400;
    }

    &__title {
        margin: 0 0 14px;

        @include media('≥tablet') {
            margin: 0 0 4px;
            padding: 15px 8px;
        }

        h3 {
            margin: 0;
        }
    }

    .nav-select {
        @include media('≥tablet') {
            display: none;
        }
    }
}

.title-avatar {
    display: flex;
    align-items: center;

    &--edit,
    &--add {
        .title-avatar__visual {
            &:after {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 16px;
                height: 16px;
                font-size: 10px;
                line-height: 1;
                border-radius: 50%;
                content: '\e928';
                background: $white;
                font-family: $icons;
                color: $gray-900;
            }
        }
    }

    &--edit {
        .title-avatar__visual {
            &:after {
                content: '\e938';
            }
        }
    }

    &--tab-content {
        .title-avatar__name {
            font-weight: $normal;
            font-size: 16px;
        }


    }

    &__visual {
        width: 48px;
        height: 48px;
        background-color: $gray-600;
        color: $gray-550;
        font-size: 24px;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px 0 0;
    }

    &__name {
        display: block;
        font-weight: $bold;
        font-size: 18px;
        line-height: 24px;
    }

    &__time {
        color: $gray-550;
        font-size: 14px;
    }
}

.settings-menu {
    margin: 0;
    padding: 24px 0 16px;
    list-style: none;

    @include media('≥tablet') {
        padding: 40px 17px 16px 8px;
    }

    >li {
        padding: 0 16px;
        border-bottom: 1px solid $gray-400;

        @include media('≥tablet') {
            border: 0;
            padding: 0;
        }

        &:first-child {
            border-top: 1px solid $gray-400;

            @include media('≥tablet') {
                border: 0;

            }
        }

        +li {
            @include media('≥tablet') {
                margin-top: 32px;
            }
        }
    }
}

.opener-page {
    position: relative;
    padding: 15px 0 16px 32px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;
    color: $gray-900;
    transition: color $animation-speed;

    @include media('≥tablet') {
        padding: 0 0 0 32px;
    }

    li.active &,
    &:hover {
        color: $violet;
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        text-align: center;
        font-size: 24px;
        line-height: 1;
    }
}

.personal-info {
    .title-avatar {
        margin-bottom: 24px;
    }

    .button-wrap {
        padding: 9px 0 0;

        .btn {
            display: block;
            width: 100%;
            padding: 15px 16px;
            font-weight: $semibold;
        }
    }
}

.inputs-wrap {

    select,
    input {
        margin: 0 0 24px;
    }

    .select-custom-2 {
        border-color: $gray-600;
    }
}


.show-more {
    >input[type="checkbox"] {
        position: absolute;
        visibility: hidden;
        opacity: 0;

        &:checked {
            +.show-more__opener {

                &:after {
                    content: "\e904";
                    background: $gray-900;
                }
            }

            ~.show-more__slide {
                display: block;
            }
        }
    }

    &__opener {
        display: block;
        position: relative;
        padding: 0 31px 0 0;
        margin: 0 0 25px;
        font-size: 16px;
        line-height: 1.5;
        color: $gray-900;

        @include media('≥tablet') {
            padding: 0 40px 0 0;
        }

        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            border: 2px solid $gray-900;
            border-radius: 8px;
            content: "";
            background: transparent;
            font-family: $icons;
            color: $white;
            font-size: 8px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__slide {
        display: none;
    }
}
