@use '../abstracts/variables' as *;
@use '../abstracts/mixins' as *;
@use '../include-media/include-media' as *;

// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site
// -----------------------------------------------------------------------------
[id="footer"] {
    color: $gray-700;
    font-size: 14px;
    line-height: 16px;
    font-weight: $normal;
    letter-spacing: 0.03em;

    a {
        color: inherit;

        &:hover {
            color: $violet;
        }
    }

    .active {
        >a {
            color: $violet;
        }
    }
}

.footer-columns {
    display: none;

    @include media('≥tablet') {
        padding: 20px 0;
        border-top: 1px solid $gray-200;
        display: flex;
        justify-content: space-between;
    }

    &__col {
        display: flex;
        flex-wrap: wrap;
    }
}

.footer-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >li {
        &:before {
            content: '•';
            margin: 0 8px;
            font-size: 0.5em;
            position: relative;
            top: -2px;
        }
    }
}

.opener-footer-content {
    &__ico {
        position: relative;
        top: -2px;
        font-size: 8px;
    }
}

.footer-active {
    overflow: hidden;
}

.footer-wrap {

    @include media('≥tablet') {
        position: fixed;
        display: flex;
        align-items: flex-end;
        padding: 0;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        background: rgba($gray-700, .7);
        z-index: 999;
        opacity: 0;
        visibility: hidden;
        transition: all calc($animation-speed / 2);
    }

    .footer-active & {
        @include media('≥tablet') {
            visibility: visible;
            opacity: 1;
        }
    }

    .footer-add & {
        @include media('≥tablet') {
            position: static;
            opacity: 1;
            visibility: visible;
            background: none;
        }
    }

    &__scroll {
        @include media('≥tablet') {
            width: 100%;
            overflow: auto;
            max-height: 100vh;
        }

        .footer-add & {
            max-height: none;
            overflow: visible;
        }
    }

    &__close {
        display: none;

        @include media('≥tablet') {
            display: block;
            position: absolute;
            top: 32px;
            left: 32px;
            font-size: 23px;
            line-height: 1;
            color: $gray-900;
        }

        &:hover {
            @include media('≥tablet') {
                color: $violet;
            }
        }

        .footer-add & {
            display: none;
        }
    }
}

.footer-content {
    border-top: 1px solid $gray-200;
    padding: 39px 0 32px;

    @include media('≥tablet') {
        padding: 88px 32px 32px;
        position: relative;
        background: $white;
        border-radius: 16px;
        border: 0;
    }

    .footer-add & {

        @include media('≥tablet') {
            padding-top: 40px;
            border-top: 1px solid $gray-200;
        }
    }

    &__columns {

        @include media('≥tablet') {
            margin: 0 -15px;
            display: flex;
        }
    }

    &__col {
        margin-bottom: 40px;

        @include media('≥tablet') {
            width: 28%;
            padding: 0 15px;
            margin-bottom: 0;
        }

        &:last-child {
            display: none;

            @include media('≥tablet') {
                width: 44%;
                display: block;
            }
        }
    }

    h3 {
        margin: 0 0 24px;
        font-size: 14px;
        line-height: 16px;
        font-weight: $bold;
        letter-spacing: 0.03em;
    }

    &__menu {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: $gray-900;

        >li {
            margin: 0 0 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    &__contacts {
        margin: 0;
        padding: 0;
        font-style: normal;
        color: $gray-900;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.03em;

        >* {
            margin: 0 0 16px;
        }

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    &__bar {

        @include media('≥tablet') {
            margin: 0 -15px;
            padding: 30px 0 0;
            display: flex;
            align-items: center;
        }
    }

    &__bar-col {

        @include media('≥tablet') {
            padding: 0 15px;
        }

        &:first-child {
            @include media('≥tablet') {
                width: 28%;
            }
        }
    }

    &__copyright {
        padding: 16px 0;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.03em;

        @include media('≥tablet') {
            padding: 0;
        }

        p {
            margin: 0;
        }
    }

    &__btn {
        width: 100%;

        @include media('≥tablet') {
            max-width: 200px;
        }

        .icon-down {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            font-size: 10px;
            transition: transform $animation-speed;
            transform: scale(1);
        }
    }

    &.active {
        .footer-content__btn {
            background: $gray-600;

            .icon-down {
                top: -1px;
                transform: scale(-1);
            }
        }
    }

    &__twocolumns-text {
        padding: 32px 0 0;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;

        @include media('≥tablet') {
            display: flex;
            margin: 0 -8px;
        }

        h3 {
            margin: 0 0 8px;
        }

        p {
            margin: 0 0 16px;

            +h3 {
                padding-top: 8px;
            }
        }
    }

    &__col-text {
        @include media('≥tablet') {
            width: 50%;
            padding: 0 8px;
        }
    }
}

.list-partners {
    margin: -10px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    >li {
        padding: 10px;
        width: calc(100% / 3);
        text-align: center;
    }
}
