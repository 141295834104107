@use '../include-media/include-media' as *;

.list-buttons-header {
    margin: 0 -4px;
    padding: 0;
    list-style: none;
    display: flex;

    @include media('≥tablet') {
        margin: 0 -8px;
    }

    >li {
        padding: 0 4px;

        @include media('≥tablet') {
            padding: 0 8px;
        }
    }
}
