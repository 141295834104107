@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.heading-product {
    padding: 4px 0 0;
    margin: 0 0 25px;
    position: relative;
    display: flex;
    flex-direction: column-reverse;

    @include media('≥tablet') {
        display: block;
    }

    &__head {
        margin: 0;
        font-size: 16px;
        line-height: calc(24/16);

        @include media('≥tablet') {
            margin: 0 0 23px;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        a {
            color: $gray-900;
            transition: color $animation-speed;

            &:hover {
                color: $violet;
            }
        }
    }

    &__list-info {
        display: flex;
        flex-wrap: wrap;
        margin: 0 15px 0 0;
        padding: 0;
        list-style: none;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;

        >li {
            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin: 0 10px;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: $gray-900;
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }
        }

        [class*="icon-"] {
            margin: 0 4px 0 0;
        }
    }

    &__list-links {
        position: absolute;
        top: 28px;
        right: 28px;
        z-index: 10;
        font-size: 32px;
        line-height: 1;

        @include media('≥tablet') {
            position: static;
            display: flex;
            margin: 0 -8px;
            padding: 0;
            list-style: none;
            font-size: 14px;
            line-height: 24px;
        }

        >li {
            display: none;
            white-space: nowrap;

            @include media('≥tablet') {
                display: block;
                padding: 0 8px;
            }
        }

        [class*="icon-"] {
            @include media('≥tablet') {
                position: relative;
                top: 1px;
                margin: 0 4px 0 0;
                font-size: 16px;
            }
        }

        .heading-product__like {
            display: block;

            @include media('<tablet') {
                .icon-heart1 .path1:before {
                    color: $gray-800;
                }

                .icon-heart1 .path2:before {
                    color: $white;
                }
            }
        }
    }

    &__list-links-text {
        display: none;

        @include media('≥tablet') {
            display: inline;
        }
    }

    h1 {
        margin: 0 0 16px;
        font-size: 24px;
        font-weight: $semibold;
        line-height: calc(32/24);

        @include media('≥tablet') {
            margin: 0 0 9px;
        }

        @include media('≥desktop') {
            font-size: 32px;
        }
    }

    &__button-wrap {
        display: none;
        position: absolute;
        bottom: 16px;
        right: 16px;

        @include media('≥tablet') {
            display: block;
        }
    }
}

.heading-product-grid {
    margin: 0 0 17px;
    padding: 0;
    list-style: none;

    @include media('≥tablet') {
        margin: 0;
        display: grid;
        grid-gap: 8px;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    >li {
        display: none;

        @include media('≥tablet') {
            display: block;
        }

        &:first-child {
            display: block;

            @include media('≥tablet') {
                grid-column: 1 / 3;
                grid-row: 1 / 3;
            }
        }
    }

    .hidden {
        display: none;
    }
}

.lightbox-image {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 100%;
    border-radius: 16px;
    overflow: hidden;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
    }
}

.lightbox-video {
    &:after {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '\e920';
        font-size: 24px;
        line-height: 1;
        color: $white;
        font-family: $icons;
        background: rgba($gray-900, .5);
    }
}
