.datepicker-modal-wrap,
.datepicker-wrap {
    margin: 44px 0 51px;
    padding: 30px 0 0;
    border-top: 1px solid $gray-400;

    .ui-widget {
        width: 100% !important;

        @include media('>630px') {
            justify-content: space-between;
            display: flex !important;
        }

        .ui-datepicker-group {
            width: 100%;
            padding: 0 8px;

            @include media('>630px') {
                width: 50%;
            }
        }

        .ui-datepicker-calendar {
            width: 100%;
        }

        .ui-datepicker-header {
            padding: 0 0 11px;
            margin: 0 0 29px;
            display: flex;
            align-items: center;
            position: relative;
            border-bottom: 2px dotted $gray-400;

            &.ui-corner-right {
                flex-direction: row-reverse;
            }

            .ui-icon {
                display: none;
            }
        }

        .ui-datepicker-prev {
            display: block;
            font-size: 15px;
            line-height: 1;
            width: 20px;
            height: 20px;
            min-width: 20px;
            color: $gray-900;

            &:before {
                content: '\e90b';
                font-family: $icons;
            }
        }

        .ui-datepicker-next {
            display: block;
            font-size: 15px;
            line-height: 1;
            width: 20px;
            height: 20px;
            min-width: 20px;
            color: $gray-900;

            &:before {
                content: '\e90a';
                font-family: $icons;
            }
        }

        .ui-datepicker-title {
            flex-grow: 1;
            text-align: center;
            font-size: 24px;
            line-height: calc(36/24);
            color: $gray-900;
            font-weight: $semibold;
        }

        .ui-datepicker-month {
            text-transform: capitalize;
        }

        .ui-datepicker-calendar {

            th,
            td {
                border: 0;
                text-align: center;
                width: 40px;
                padding: 0;
            }

            th {
                padding: 0 0 15px;
                font-size: 12px;
                line-height: 14px;
                font-weight: $medium;
                color: $gray-800;
            }

            td {
                padding: 2px 0;
                font-size: 14px;
                line-height: 16px;
                font-weight: $bold;

                a {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    color: $gray-900;
                }

                &.ui-datepicker-current-day {
                    a {
                        color: $white;
                        background: $violet;
                    }
                }

                &.ui-state-disabled {
                    span {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 8px;
                        color: $gray-700;
                        font-weight: $normal;
                    }
                }
            }
        }
    }
}

.datepicker-modal-wrap {
    margin: 20px 0 40px;
    padding: 20px 0 0;

    .ui-widget {
        display: block !important;

        .ui-datepicker-prev {
            order: 1;

            &:before {
                position: relative;
                left: -10px;
            }
        }

        .ui-datepicker-title {
            order: 2;
        }

        .ui-datepicker-next {
            order: 3;
        }
    }
}

.time-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @include media('≥phone') {
        column-count: 2;
        column-gap: 30px;
    }

    @include media('≥tablet') {
        column-gap: 64px;
    }

    li {
        overflow: hidden;
        margin-bottom: 16px;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

.time-item {
    display: block;

    input {
        opacity: 0;
        position: absolute;

        &:checked {
            +.time-item__text {
                background: $gray-900;
                color: $white;
            }
        }
    }

    &__text {
        display: block;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        border-radius: 99px;
        padding: 10px 15px;
        background: $gray-200;
        color: $gray-900;
        transition: all $animation-speed;
    }
}
