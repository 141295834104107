@use '../abstracts/variables' as *;

.globus {
    position: relative;
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-700;
    font-size: 24px;
    line-height: 1;
    box-shadow: $shadow-01;
    border-radius: 50%;

    &__ico {
        display: block;
        width: 24px;
        height: 24px;
    }

    &__num {
        position: absolute;
        bottom: 8px;
        right: 6px;
        text-transform: uppercase;
    }
}
