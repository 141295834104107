.reviews {
    margin: 0 0 57px;

    @include media('≥tablet') {
        margin: 0 0 44px;
    }

    h2 {
        margin: 0 0 14px;
        font-weight: $semibold;
        font-size: 24px;
        line-height: calc(32/16);
    }

    &__button-wrap {
        padding: 7px 0;

        @include media('≥tablet') {
            padding: 20px 0;
        }
    }
}

.review {
    &__header {
        margin: 0 0 16px;
        display: flex;
        justify-content: space-between;
    }

    &__avatar {
        width: 48px;
        height: 48px;
        background-color: $gray-600;
        color: $gray-550;
        font-size: 24px;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px 0 0;
    }

    &__header-content {
        flex-grow: 1;
    }

    &__name {
        margin: 0;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: $semibold;
        color: $gray-900;
    }

    &__variant {
        color: $gray-500;
        font-size: 14px;
        line-height: calc(24/14);

        p {
            margin: 0 0 15px;
        }
    }

    &__time {
        font-size: 14px;
        line-height: calc(24/14);
        color: $gray-500;
    }

    &__rating {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        width: 100px;
        font-size: 16px;
        color: $yellow;
        line-height: 1;

        @include media('≥tablet') {
            width: 140px;
            font-size: 23px;
        }

        >li {
            padding: 0 2px;
        }
    }

    &__content {
        font-size: 16px;
        line-height: calc(24/16);

        p {
            margin: 0 0 16px;
        }
    }

    &__open-close {
        background: transparent;
        padding: 7px 16px 8px;

        &.active {
            background: $gray-200;

            .review__opener-show {
                display: none;
            }

            .review__opener-hide {
                display: block;
            }
        }
    }

    &__slide-header {
        display: flex;
        justify-content: space-between;
    }

    &__slide-avatar {
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
    }

    &__header-content {
        flex-grow: 1;
    }

    &__slide-content {
        color: $gray-500;

        p {
            margin: 0 0 16px;
        }
    }

    &__opener {
        color: $gray-900;
        font-weight: normal;
        font-size: 14px;
        line-height: calc(24/14);
        font-weight: $bold;
    }

    &__opener-hide {
        display: none;
    }
}

.reviews-list {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    width: 100%;

    @include media('≥tablet') {
        margin: 0 0 55px;
        column-count: 2;
        column-gap: 30px;
    }

    @include media('≥widescreen') {
        column-gap: 76px;
    }

    >li {
        padding: 0 0 23px;
        margin: 0 0 24px;
        border-bottom: 1px solid $gray-600;

        @include media('≥tablet') {
            transform: translateZ(0);
            -webkit-column-break-inside: avoid;
            break-inside: avoid;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            overflow: hidden;
        }
    }
}
