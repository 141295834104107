.grid-post {
    margin: 0 0 27px;
    padding: 4px 0 0;
    list-style: none;

    >li {
        +li {
            margin-top: 24px;
        }
    }
}

.card-post {
    @include media('≥600px') {
        display: flex;
    }

    @include media('≥tablet') {
        display: block;
    }

    @include media('≥desktop') {
        display: flex;
    }

    &__visual {
        display: block;
        overflow: hidden;
        min-height: 200px;
        width: 100%;
        margin: 0 0 24px;
        border-radius: 16px;

        @include media('≥600px') {
            min-height: 200px;
            width: 42.7%;
            min-width: 42.7%;
            margin: 0 24px 0 0;
            border-radius: 16px 0 0 16px;
        }

        @include media('≥tablet') {
            width: 100%;
            margin: 0 0 24px;
            border-radius: 16px;
        }

        @include media('≥desktop') {
            min-height: 200px;
            width: 42.7%;
            min-width: 42.7%;
            margin: 0 24px 0 0;
            border-radius: 16px 0 0 16px;
        }

        img {
            display: block;
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: transform $animation-speed;
        }
    }

    &__body {
        align-self: center;

        @include media('≥600px') {
            padding: 10px 0;
        }

        @include media('≥tablet') {
            padding: 0;
        }

        @include media('≥desktop') {
            padding: 10px 0;
        }
    }

    &__name {
        display: block;
        margin: 0 0 9px;
        font-weight: $medium;
        font-size: 12px;
        line-height: 16px;
        color: $gray-550;
    }

    h2 {
        margin: 0 0 15px;

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    a.card-post__visual {
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }
}

.tag-list {
    margin: -2px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-weight: $medium;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;

    >li {
        padding: 2px;
    }

    a {
        padding: 2px 6px;
        color: $violet;
        display: block;
        border: 2px solid $violet;
        border-radius: 4px;
        transition: all $animation-speed;

        &:hover {
            background: $violet;
            color: $white;
        }
    }
}
