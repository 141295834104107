.search-header {
    font-size: 16px;
    line-height: 24px;

    &__form {
        padding: 8px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 99px;
        overflow: hidden;

        @include media('≥tablet') {
            flex-direction: row-reverse;
            box-shadow: $shadow-01;
        }
    }

    input[type=search]:not(textarea),
    &__search {
        padding: 4px 8px;
        border: 0;
        height: 32px;
        background: none;
        width: 100%;
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: $gray-700;

        &::placeholder {
            color: $gray-500;
        }
    }

    &__button {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 0;
        color: $gray-700;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        line-height: 1;
        background: none;

        @include media('≥tablet') {
            font-size: 16px;
            color: $white;
            background: $violet;
        }
    }
}
