// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/
// Color system

@use "sass:map";
@use "sass:color";

$white: #fff !default;
$gray: #ddd !default;
$gray-100: #ECECEC; //
$gray-200: #F7F7F7; //
$gray-300: #D6D6D6; //
$gray-400: #DBDBDB; //
$gray-500: #A5A3A9; //
$gray-550: #939393; //
$gray-600: #E8E8EA; //
$gray-700: #717171; //
$gray-800: #615E69; //
$gray-900: #0F0D15; // 222
$black: #000 !default;

$grays: () !default;

$grays: map.merge((
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
), $grays);


$blue: #003CFF; //
$violet: #8456CA; //
$violet-500: #9E73E6; //
$violet-900: #6639A5; //
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #DD4241; //
$orange: #fd7e14 !default;
$yellow: #FFD166; //
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;

$colors: map.merge((
    "blue": $blue,
    "violet": $violet,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800
), $colors);


$shadow-01: 0 0 6px rgba($black, .02), 0 2px 4px rgba($black, .08);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;

$theme-colors: map.merge((
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
), $theme-colors);


// fonts name
$base-font-sans-serif: 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$base-font-serif: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;

$base-font: 'Inter', $base-font-sans-serif;
$icons: 'icomoon';

// Body
$base-text-color: $gray-800; //
$base-background-color: $white !default;
$base-background-body: $white !default;
$base-background-footer: $base-background-body !default;
$font-size-base: 16px; //
$line-height-base: 24px; //
$font-size-base-lagre: 16px; //
$line-height-base-lagre: 24px; //
$base-font-family: $base-font; //
$base-min-width: 360px;

// base variables
$base-radius: 0;
$base-radius-small: 0;

// container settings
$max-width-center-container: 1056px;
$gutter: 16px;
$padding-container: 0 $gutter;

// grid sistem
$grid: (
  ph: 'phone',
  sm: 'tablet',
  md: 'desktop'
);

// font weight
$thin: 100;
$light-font: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extra: 800;
$black-font: 900;

// default margins
$vertical-rhythm: $font-size-base + 4;
$widget-margin: 20px;

// Liks
$base-link-color: $violet;
$base-link-hover-color: $black;
$text-decoration: none;
$text-decoration-hover: none;

// Headers size
$title-palette: (
  mobile: ('h1': 32,
    'h2': 24,
    'h3': 18,
    'h4': 18,
    'h5': 18,
    'h6': 14),
  tablet: ('h1': 32,
    'h2': 24,
    'h3': 18,
    'h4': 18,
    'h5': 18,
    'h6': 14),
  desktop: ('h1': 32,
    'h2': 24,
    'h3': 18,
    'h4': 18,
    'h5': 18,
    'h6': 14),
  widescreen: ('h1': 32,
    'h2': 24,
    'h3': 18,
    'h4': 18,
    'h5': 18,
    'h6': 14)
);

// headings style
$headings-font-family: $base-font-family;
$headings-color: $gray-900; //

// Buttons
// btn default
$btn-border-width: 1px; //
// $btn-border:                        $btn-border-width solid transparent !default;
$btn-border-radius: 8px; //
$btn-border-radius-sm: $base-radius-small;
$btn-padding: 8px 16px; //
$btn-padding-sm: 4px 10px; //
$btn-font-size: 16px; //
$btn-line-height: 24px; //

$btn-background: $white; //
$btn-border: $btn-border-width solid $gray-900; //
$btn-color: $gray-900; //
// hover
$btn-hover-background: $gray-100; //
$btn-hover-border: $btn-border; //
$btn-hover-color: $gray-900; //

// btn primary
$btn-background-primary: $primary !default;
$btn-border-primary: $primary !default;
$btn-color-primary: $white !default;
// hover
$btn-hover-background-primary: color.adjust($primary, $lightness: -10%) !default;
$btn-hover-border-primary: $btn-hover-background-primary !default;
$btn-hover-color-primary: $white !default;

// btn warning
$btn-background-warning: $warning !default;
$btn-border-warning: $btn-background-warning !default;
$btn-color-warning: $white !default;
// hover
$btn-hover-background-warning: color.adjust($warning, $lightness: -10%) !default;
$btn-hover-border-warning: $btn-hover-background-warning !default;
$btn-hover-color-warning: $white !default;

// Forms
$input-font-size: $font-size-base; // font size for forms elements
$input-line-height: $input-font-size + 2; // line height for forms elements
$input-font-family: $base-font;
$input-padding-vertical: 10px !default; // padding for forms elements
$input-padding-horizontal: 20px !default; // padding for forms elements
$input-padding: $input-padding-vertical $input-padding-horizontal !default;
$input-border-width: 1px !default; // border input's width
$input-border: $input-border-width solid $black !default; // border for forms elements
$input-border-radius: 0 !default; // border radius for forms elements
$inpH: $input-border-width + 2 * $input-padding-vertical + $input-line-height;
$input-height: (
  2 * ($input-border-width + $input-padding-vertical) + $input-line-height) !default; // height for forms elements
//submit
$submit-background: $btn-background;
$submit-color: $btn-color;
$submit-height: $input-height;
$submit-border: $btn-border;
$submit-padding: $input-padding;
$submit-font-size: $input-font-size;
$submit-line-height: $input-line-height;
// submit hover
$submit-hover-background: $btn-hover-background; // submit background hover
$submit-hover-color: $btn-hover-color; // submit color hover
//focus
$input-focus-border-color: $black !default; // border color for forms elements
//placeholder
$placeholder-color: $gray; //
// select
$select-height: $input-height;
$select-border: $input-border;
$select-border-radius: $input-border-radius;
$select-padding: $input-padding;
$select-font-size: $input-font-size;
$select-line-height: $input-line-height;

// animation speed
$animation-speed: 0.5s;
