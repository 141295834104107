.menu-drop {
    position: absolute;
    z-index: 99;
    top: calc(100% + 10px);
    right: 0;
    padding: 16px 0;
    width: 240px;
    background: $white;
    box-shadow: 0 0 4px rgba($black, 0.04), 0 4px 8px rgba($black, 0.06);
    border-radius: 16px;
    font-weight: $normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    opacity: 0;
    visibility: hidden;
    transition: all $animation-speed;

    .menu-active & {
        opacity: 1;
        visibility: visible;
    }

    a {
        display: block;
        padding: 8px 24px;
        color: $gray-900;

        &:hover {
            background: $gray-200;
        }
    }

    &__login {
        margin: 0 0 16px;
        padding: 0 0 16px;
        list-style: none;
        border-bottom: 1px solid $gray-600;
    }

    &__menu {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}