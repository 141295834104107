.two-columns-content {
    @include media('≥tablet') {
        display: flex;
        justify-content: space-between;
    }

    &__col {
        &:first-child {
            @include media('≥tablet') {
                width: 280px;
                min-width: 280px;
            }

            @include media('≥desktop') {
                width: 344px;
                min-width: 344px;
            }
        }

        &:last-child {
            @include media('≥tablet') {
                width: calc(100% - 280px);
                padding-left: 24px;
            }

            @include media('≥desktop') {
                width: calc(100% - 344px);
            }
        }
    }
}