.voucery-list {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        margin: 0 0 24px;
    }
}

.voucery-item {
    font-size: 14px;
    line-height: calc(24/14);

    &__visual {
        display: block;
        margin: 0 0 8px;
        width: 100%;
        height: 160px;
        border-radius: 16px;
        overflow: hidden;

        img {
            width: 100%;
            max-width: none;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            transform: scale(1.05);
            transition: transform $animation-speed;
        }
    }

    a.voucery-item__visual {
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__title {
        margin: 0;
        font-size: 14px;
        line-height: calc(24/14);
        font-weight: $bold;
        color: $gray-900;

        a {
            color: inherit;
            transition: color $animation-speed;

            &:hover {
                color: $violet;
            }
        }
    }

    &__info {
        margin: 0 0 0 5px;
        font-size: 24px;
        line-height: 1;
        color: $gray-550;
        transition: color $animation-speed;

        &:hover {
            color: $violet;
        }
    }

    &__applied {
        margin: 0 0 15px;
        color: $gray-800;

        time {
            color: $gray-900;
        }
    }

    &__cod {
        margin: 0 0 31px;
        display: flex;
        justify-content: space-between;
        color: $gray-800;
    }

    &__footer {
        display: flex;
        justify-content: space-between;

        .btn {
            flex-grow: 1;
            padding: 7px 16px;
            border-color: $gray-550;
        }
    }
}

.function {
    position: relative;
    z-index: 5;
    margin-left: 16px;

    &__opener {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        border: 1px solid $gray-550;
        border-radius: 8px;
        color: $gray-900;
        font-size: 24px;
        line-height: 1;
        transition: background $animation-speed;

        &:hover {
            background: $gray-100;
        }
    }

    &__slide {
        position: absolute;
        padding: 24px;
        right: 0;
        top: 100%;
        width: 240px;
        background: $white;
        box-shadow: 0 0 4px rgba($black, 0.04), 0 4px 8px rgba($black, 0.06);
        border-radius: 16px;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;

        a {
            color: inherit;
            transition: color $animation-speed;

            &:hover {
                color: $violet;
            }
        }
    }
}

.copy-cod {
    display: flex;
    align-items: center;

    &__text {
        color: $gray-900;
    }

    &__button {
        color: inherit;
        font-size: 24px;
        line-height: 1;
        margin: 0 0 0 5px;
    }
}

.arhive-voucher {
    font-size: 14px;
    line-height: 24px;
    color: $gray-800;

    &__content {
        &.active {
            .arhive-voucher__opener {
                background: #DFDEE0;

                .icon-down {
                    transform: scaleY(-1);
                }
            }
        }
    }

    &__visual {
        margin: 0 0 17px;
        width: 100%;
        height: 160px;
        border-radius: 16px;
        overflow: hidden;

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    p {
        margin: 0 0 15px;

        +h2 {
            padding-top: 10px;
        }
    }

    h1 {
        margin: 0;
        font-weight: $semibold;
        font-size: 24px;
        line-height: 32px;
        color: $gray-900;
    }

    h2 {
        margin: 0 0 7px;
    }

    &__applied {
        margin: 0 0 7px;

        time {
            font-size: 16px;
            font-weight: $medium;
            color: $gray-900;
        }
    }

    &__cod-row {
        margin: 0 0 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__cod {
        font-size: 16px;
        font-weight: $medium;
        color: $gray-900;
    }

    &__name-row {
        margin: 0 0 25px;
        padding: 10px 0 16px;
        border-top: 1px solid #DFDEE0;
        border-bottom: 1px solid #DFDEE0;
    }

    &__name-row-edit {
        margin: 0 0 16px;
        align-items: flex-end;
        display: flex;
        justify-content: space-between;
    }

    &__name-edit {
        font-size: 16px;
        font-weight: $medium;

        a {
            position: relative;
            top: 5px;
            font-size: 24px;
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    &__name-input-wrap {
        position: relative;

        input {
            padding-right: 50px;
            border-color: $gray-900;
        }

        .input-succes+.text-succes,
        .input-error+.text-succes+.text-error {
            margin-top: 0;
        }
    }

    &__button-name {
        position: absolute;
        height: 56px;
        inset: 0 0 auto auto;
        width: 50px;
        min-width: 50px;
        background: none;
        border: 0;
        color: $gray-900;

        &:hover {
            background: none;
            color: $violet;
        }

        &:after {
            padding: 1px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 18px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            font-size: 8px;
            line-height: 1;
            content: '\e904';
            font-family: $icons;
            border: 1px solid currentColor;
        }
    }

    &__opener {
        margin: 32px 0 25px;
        display: block;
        padding: 7px 15px;
        color: $gray-900;
        border-radius: 8px;
        border: 1px solid $gray-900;
        background: $white;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
        font-weight: $semibold;

        &:hover {
            background: #DFDEE0;
        }

        .icon-down {
            transform: scaleY(1);
            display: inline-block;
            color: $gray-800;
            font-size: 10px;
        }
    }

    &__slide {
        font-size: 16px;
        padding-bottom: 31px;
    }

    ul:not([class]) {
        margin: 0 0 25px;
        padding: 0 0 0 9px;
        list-style: none;

        >li {
            position: relative;
            padding-left: 15px;

            &:before {
                position: absolute;
                top: 10px;
                left: 0;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                content: '';
                background: currentColor;
            }
        }
    }
}

.options {
    padding: 10px 0 0;
    margin: 0 0 15px;
    border-top: 1px solid #DFDEE0;
    border-bottom: 1px solid #DFDEE0;
}

.options-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
    line-height: 24px;

    >li {
        margin: 0 0 19px;
    }

    &__row {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__row-text {
        font-size: 12px;
        font-weight: $medium;
    }

    a {
        color: $gray-900;

        &:hover {
            color: $violet;
        }

        span {
            position: relative;
            top: 5px;
            margin-left: 5px;
            font-size: 24px;
        }
    }
}

.cancel-voucher-wrap {
    padding: 20px 0;
    font-size: 14px;
    line-height: 24px;

    a {
        color: $gray-900;

        &:hover {
            color: $violet;
        }
    }

    span {
        position: relative;
        top: 5px;
        margin: 0 0 0 5px;
        font-size: 22px;
    }
}

.cards-voucher-list {
    margin: 0;
    padding: 0 0 5px;
    list-style: none;

    @include media('≥tablet') {
        margin: -10px 0 0;
    }

    >li {
        margin: 0 0 15px;
    }
}

.card-voucher {
    padding: 16px;
    color: $gray-800;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #DFDEE0;
    border-radius: 16px;

    &.style-1 {
        .card-voucher__status {
            color: $green;
        }
    }

    &.style-2 {
        .card-voucher__status {
            color: $red;
        }
    }

    &.style-3 {
        .card-voucher__status {
            color: $gray-550;
        }
    }

    &__content {
        margin: 0 0 15px;
        display: flex;
    }

    &__content-card {
        flex-grow: 1;

        .card-voucher__row {
            &:first-child {
                margin-bottom: 15px;
            }
        }
    }

    &__number {
        color: $gray-900;
        font-weight: $bold;
    }

    &__price {
        font-weight: $medium;
        color: $gray-900;
    }

    time {
        color: $gray-900;
    }

    &__visual {
        margin: 0 16px 0 0;
        width: 88px;
        min-width: 88px;
        height: 88px;
        border-radius: 16px;
        overflow: hidden;

        img {
            display: block;
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
    }

    &__row-col {
        max-width: 49%;
    }

    &__footer {
        padding: 7px 0 0;
        color: $gray-900;
        border-top: 1px solid #DFDEE0;

        .lightbox {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    &__footer-row {
        margin: 0 0 16px;
        display: flex;
        justify-content: space-between;

    }

    &__footer-col {
        max-width: 49%;
    }

    .btn {
        display: block;
        width: 100%;
        padding: 7px 16px;
    }
}

.nakupy-archive-list {
    margin: 0;
    padding: 0 0 5px;
    list-style: none;

    >li {
        margin: 0 0 15px;
    }
}

.nakupy-archive {
    font-size: 14px;
    line-height: calc(24/14);

    &__visual {
        display: block;
        margin: 0 0 8px;
        width: 100%;
        height: 160px;
        border-radius: 16px;
        overflow: hidden;
        opacity: 0.3;

        img {
            width: 100%;
            max-width: none;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__title {
        margin: 0;
        font-size: 14px;
        line-height: calc(24/14);
        font-weight: $bold;
        color: #DFDEE0;
    }

    &__applied {
        margin: 0 0 15px;
        color: $gray-800;

        time {
            color: $gray-900;
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn {
            flex-grow: 1;
            max-width: 62%;
            padding: 7px 16px;
            border-color: $gray-550;
        }
    }
}

.list-reserve {
    margin: 0;
    padding: 0 0 5px;
    list-style: none;

    >li {
        margin: 0 0 8px;
    }
}

.reserve {
    padding: 23px 16px;
    color: $gray-800;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #DFDEE0;
    border-radius: 16px;

    &.style-1 {
        .reserve__value--status {
            color: $red;
        }
    }

    &.style-2 {
        .reserve__value--status {
            color: $green;
        }
    }

    &.style-3 {
        .reserve__value--status {
            color: $gray-550;
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        +.reserve__row {
            margin-top: 16px;
        }
    }

    &__text {
        margin-right: 5px;
    }

    &__value {
        color: $gray-900;
    }
}