@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;
@use '../abstracts/functions' as *;

// Typography

@include title-size('≥');

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  font-family: $headings-font-family;
  color: $headings-color;
  font-weight: bold;
  margin: 0 0 10px;

  @include media('≥tablet') {
    margin-bottom: 20px;
  }
}

h1 {
  line-height: calc(40/32);
  font-weight: $semibold;
}

h2 {
  line-height: calc(32/24);
  font-weight: $semibold;
}

h3,
.h3 {
  margin: 0 0 20px;
  line-height: calc(40 / 32);
  // font-weight: $semibold;
  font-weight: $bold;
  letter-spacing: 0.03em;
}

h4 {
  line-height: calc(24 / 18);
  font-weight: $semibold;
}

h5,
.h5 {
  margin: 0 0 15px;
  // font-weight: $semibold;
  font-weight: $medium;
  line-height: calc(24 / 18);
  letter-spacing: 0.03em;
}

h6 {
  font-weight: $normal;
  margin: 0;
}

p {
  margin: 0 0 20px;
}

a {
  color: $base-link-color;
  text-decoration: $text-decoration;

  &:hover,
  &:focus {
    color: $base-link-hover-color;
    text-decoration: $text-decoration-hover;
  }
}

a[href*="mailto:"] {
  word-wrap: break-word;
}

dl dt {
  color: $gray-900;
}

mark {
  background: none;
}

figure {
  margin-bottom: 20px;
}
