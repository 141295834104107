@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.banner {
    display: none;

    @include media('≥tablet') {
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        border: 1px solid $gray-400;
        border-radius: 16px;
        overflow: hidden;
        font-size: 16px;
        line-height: calc(24/18);
    }

    @include media('≥desktop') {
        font-size: 18px;
    }

    @include media('≥widescreen') {
        font-size: 22px;
    }

    @include media('≥xl') {
        font-size: 26px;
    }

    .tag-list {
        margin-bottom: 14px;
    }

    &__content {
        width: 50%;
        color: $gray-900;
        padding: 30px 5% 30px 6%;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1 {
        @include media('≥tablet') {
            margin: 0 0 10px;
            font-weight: $semibold;
            font-size: 3vw;
            line-height: calc(40/32);
        }

        @include media('≥desktop') {
            font-size: 32px;
        }

        @include media('≥widescreen') {
            font-size: 40px;
        }

        a {
            color: inherit;
            transition: color $animation-speed;

            &:hover {
                color: $violet;
            }
        }
    }

    &__visual {
        width: 50%;
        display: flex;
        align-self: stretch;
        overflow: hidden;

        a {
            display: block;
            width: 100%;

            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            transform: scale(1);
            transition: transform $animation-speed;
        }
    }

    &--mob-show {
        @include media('<tablet') {
            display: flex;
            flex-direction: column-reverse;
        }

        .banner__content {
            @include media('<tablet') {
                width: 100%;
                padding: 16px 0 33px;
            }
        }

        .banner__visual {
            @include media('<tablet') {
                width: 100%;
                border-radius: 16px;
                overflow: hidden;
            }
        }
    }

    &--blog {
        h1 {}

        .banner__content {}

        .banner__visual {}
    }

    &--product {
        h1 {}

        .banner__content {}

        .banner__visual {}
    }

    &--category {
        h1 {}

        .banner__content {}

        .banner__visual {}
    }
}
