@use '../abstracts/variables' as *;

.ajax-loader {
    overflow: hidden;

    &:after {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: rgba($white, .9);
        background-image: url("../images/ajax-loader.gif");
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }
}
