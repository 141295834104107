@use '../abstracts/variables' as *;

.form-actiivation {
    padding: 0 0 30px;
    max-width: 688px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    h2 {
        margin: 0 0 24px;
        font-size: 18px;
        line-height: 24px;
        font-weight: $bold;
    }

    input {
        border-color: $gray-900;

        &::placeholder {
            color: $gray-900;
        }
    }

    .activate-card {
        padding: 0;
        border: 0;
        margin-bottom: 40px;

        &__input {
            .icon-check {
                display: none;

                &:before {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: $green;
                    width: 20px;
                    height: 20px;
                    font-size: 9px;
                    line-height: 1;
                    border-radius: 50%;
                    border: 1px solid $green;
                }
            }
        }

        button {
            width: 20px;
        }

        .input-succes {
            ~button {
                .icon-arrow-right {
                    display: none;
                }

                .icon-check {
                    display: block;
                }
            }
        }
    }

    &__row {
        padding: 24px 0;
        border-top: 1px solid $gray-400;

        .activate-card {
            margin-bottom: 0;
        }

        &--add {
            border: 0;
            padding-top: 0;
        }
    }

    &__footer {
        text-align: right;

        .btn {
            max-width: 324px;
            width: 100%;
            padding: 15px 16px;
        }
    }
}

.hideShowPassword-wrapper {
    button {
        display: block !important;
        right: 15px !important;
        width: 40px;
    }
}
