.range-list {
    margin: -8px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    >li {
        padding: 8px;
    }
}

.range-item {
    display: block;

    input {
        position: absolute;
        visibility: hidden;
        opacity: 0;

        &:checked {
            +.range-item__text {
                background: $gray-900;
                color: $gray-200;
            }
        }
    }

    &__text {
        display: block;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        color: $gray-900;
        letter-spacing: 0.03em;
        font-weight: $normal;
        border-radius: 99px;
        background: $gray-200;
        transition: all $animation-speed;
    }
}