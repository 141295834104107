@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.why-shop {
    padding: 58px 30px;
    border-radius: 16px;
    border: 1px solid $gray-600;
    font-size: 16px;
    line-height: calc(24/16);

    &__hold {
        max-width: 680px;
        margin: 0 auto;
    }

    h3 {
        margin: 0 0 23px;
    }

    &__list {
        margin: 0 0 34px;
        padding: 0;
        list-style: none;

        @include media('≥tablet') {
            margin: 0 -15px 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        >li {
            padding: 17px 0;

            @include media('≥tablet') {
                width: calc(100% / 3);
                padding: 16px 15px;
            }
        }
    }

    &__button-wrap {
        text-align: center;
    }
}

.why-shop-item {
    width: 100%;
    display: flex;
    align-items: center;

    &__icon {
        width: 40px;
        min-width: 40px;
        margin: 0 16px 0 0;
    }

    &__text {
        p {
            margin: 0;
        }
    }
}
