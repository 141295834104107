@use '../include-media/include-media' as *;

.logo {
    @include media('≥desktop') {
        padding: 4px 0;
    }

    &__mobile {
        display: block;

        @include media('≥desktop') {
            display: none;
        }
    }

    &__desktop {
        display: none;

        @include media('≥desktop') {
            display: block;
        }
    }
}
