@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.product-checkout {
    padding: 0 0 11px;
    margin: 0 0 16px;
    border-bottom: 1px solid $gray-600;

    &__visual {
        border-radius: 16px;
        overflow: hidden;
        margin-bottom: 16px;

        @include media('≥tablet') {
            margin-bottom: 0;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    &__columns {
        @include media('≥tablet') {
            display: flex;
            justify-content: space-between;
        }

        &--add {
            display: flex;
            flex-direction: column-reverse;

            @include media('≥tablet') {
                flex-direction: row;
            }
        }
    }

    &__col {

        @include media('≥tablet') {
            width: 50%;
        }
    }

    &__content {
        font-size: 16px;
        line-height: calc(24/16);
        color: $gray-800;

        @include media('≥tablet') {
            padding: 0 0 0 32px;
        }

        p {
            margin: 0 0 16px;
        }
    }

    &__price {
        padding: 0 0 16px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: $medium;
        letter-spacing: 0.03em;
    }

    &__price-new {
        color: $red;
        margin-right: 8px;
    }

    &__price-old {
        color: $gray-500;
        text-decoration: line-through;
    }

    &__quantity_wrap {

        @include media('≥tablet') {
            padding-left: 32px;
        }
    }
}

.list-el {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 24px;
    font-weight: $normal;

    >li {
        padding: 16px 0 0;
        margin: 0 24px 0 0;
    }

    a {
        color: $gray-500;
        position: relative;
        padding-left: 34px;

        &:hover {
            color: $gray-900;
        }
    }

    [class^="icon-"] {
        position: absolute;
        left: 0;
        top: -1px;
        font-size: 24px;
    }

    .path1 {
        display: flex;
    }
}

.related-products {
    padding: 49px 0 0;

    @include media('≥tablet') {
        padding: 25px 0 0;
    }
}

.list-checkbox {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        margin: 0 0 12px;
    }
}

.checkbox-custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 31px 0 0;
    min-height: 48px;
    font-size: 16px;
    line-height: calc(24/16);
    color: $gray-900;

    @include media('≥tablet') {
        padding: 0 40px 0 0;
    }

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        &:checked {
            +span {
                &:after {
                    content: '\e904';
                    background: $gray-900;
                }
            }
        }

        +span {
            &:after {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                border: 2px solid $gray-900;
                border-radius: 8px;
                content: '';
                background: transparent;
                font-family: $icons;
                color: $white;
                font-size: 8px;
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    span {
        display: block;

        @include media('≥tablet') {
            width: 50%;
        }
    }

    &__num {
        padding-left: 15px;
        text-align: right;
        white-space: nowrap;
    }
}
