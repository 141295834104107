@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot');
  src: url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.woff') format('woff'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*="icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye:before {
  content: "\e93c";
}

.icon-eye-slash:before {
  content: "\e93d";
}

.icon-discover:before {
  content: "\e93b";
}

.icon-glass:before {
  content: "\e93a";
}

.icon-wallet-add:before {
  content: "\e939";
}

.icon-edit-2:before {
  content: "\e938";
}

.icon-add:before {
  content: "\e928";
}

.icon-arrow-left:before {
  content: "\e929";
}

.icon-arrow-right:before {
  content: "\e92a";
}

.icon-calendar:before {
  content: "\e92b";
}

.icon-card-add:before {
  content: "\e92c";
}

.icon-card-remove:before {
  content: "\e92d";
}

.icon-card-tick:before {
  content: "\e92e";
}

.icon-direct:before {
  content: "\e92f";
}

.icon-document-copy:before {
  content: "\e930";
}

.icon-document-download:before {
  content: "\e931";
}

.icon-edit:before {
  content: "\e932";
}

.icon-info-circle:before {
  content: "\e933";
}

.icon-logout:before {
  content: "\e934";
}

.icon-more:before {
  content: "\e935";
}

.icon-setting:before {
  content: "\e936";
}

.icon-subtitle:before {
  content: "\e937";
}

.icon-trash:before {
  content: "\e923";
}

.icon-building:before {
  content: "\e924";
}

.icon-call:before {
  content: "\e925";
}

.icon-clock:before {
  content: "\e926";
}

.icon-location:before {
  content: "\e927";
}

.icon-call1:before {
  content: "\e91c";
}

.icon-direct-normal:before {
  content: "\e91d";
}

.icon-star:before {
  content: "\e91e";
}

.icon-global:before {
  content: "\e91f";
}

.icon-play:before {
  content: "\e920";
}

.icon-play1 .path1:before {
    content: "\e920";
    color: rgb(255, 255, 255);
}

.icon-play1 .path2:before {
    content: "\e920";
    margin-left: -1em;
    color: rgb(113, 113, 113);
}

.icon-gallery:before {
  content: "\e921";
}

.icon-export:before {
  content: "\e922";
}

.icon-heart1 .path1:before {
  content: "\e91a";
  color: rgb(255, 255, 255);
}

.icon-heart1 .path2:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(113, 113, 113);
}

.icon-bag-2:before {
  content: "\e900";
}

.icon-bi_instagram:before {
  content: "\e901";
}

.icon-cake:before {
  content: "\e902";
}

.icon-car:before {
  content: "\e903";
}

.icon-check:before {
  content: "\e904";
}

.icon-close-circle:before {
  content: "\e905";
}

.icon-close-square:before {
  content: "\e906";
}

.icon-crown:before {
  content: "\e907";
}

.icon-down:before {
  content: "\e908";
}

.icon-up:before {
  content: "\e909";
}

.icon-next:before {
  content: "\e90a";
}

.icon-prev:before {
  content: "\e90b";
}

.icon-flash:before {
  content: "\e90c";
}

.icon-happyemoji:before {
  content: "\e90d";
}

.icon-heart:before {
  content: "\e90e";
}

.icon-location1:before {
  content: "\e90f";
}

.icon-map:before {
  content: "\e910";
}

.icon-mdi_youtube:before {
  content: "\e911";
}

.icon-menu:before {
  content: "\e912";
}

.icon-profile:before {
  content: "\e913";
}

.icon-profile-tick:before {
  content: "\e914";
}

.icon-search-check:before {
  content: "\e915";
}

.icon-search-normal:before {
  content: "\e916";
}

.icon-setting-4:before {
  content: "\e917";
}

.icon-uil_facebook-f:before {
  content: "\e918";
}

.icon-weight:before {
  content: "\e919";
}
