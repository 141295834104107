.message {
    margin-bottom: 16px;

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        &:checked {
            +label {
                &:after {
                    content: '\e904';
                    background: $gray-900;
                }
            }

            ~.message__slide {
                display: block;
            }
        }
    }

    label {
        display: block;
        position: relative;
        padding: 0 31px 0 0;
        font-size: 16px;
        line-height: calc(24/16);
        color: $gray-900;

        @include media('≥tablet') {
            padding: 0 40px 0 0;
        }

        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            border: 2px solid $gray-900;
            border-radius: 8px;
            content: '';
            background: transparent;
            font-family: $icons;
            color: $white;
            font-size: 8px;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__slide {
        padding: 24px 0 0;
        display: none;
    }
}