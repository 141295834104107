@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.faq {
    margin: 0 0 64px;

    h2 {
        margin: 0 0 23px;
        font-size: 24px;
        line-height: calc(32/24);
    }
}

.faq-accordion {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    @include media('≥tablet') {
        column-count: 2;
        column-gap: 30px;
    }

    @include media('≥desktop') {
        column-gap: 76px;
    }

    >li {
        margin-top: -1px;
        border-bottom: 1px solid $gray-500;
        border-top: 1px solid $gray-500;

        @include media('≥tablet') {
            transform: translateZ(0);
            -webkit-column-break-inside: avoid;
            break-inside: avoid;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            overflow: hidden;
        }
    }

    &__opener {
        padding: 24px 0;
        display: block;
        position: relative;
        color: $gray-900;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: $medium;
        transition: color $animation-speed;

        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%) scale(1);
            font-size: 8px;
            line-height: 1;
            content: '\e908';
            font-family: $icons;
            transition: transform $animation-speed;
        }
    }

    .active {
        .faq-accordion__opener {
            color: $violet;

            &:after {
                color: $gray-500;
                transform: translateY(-50%) scale(-1);
            }
        }
    }

    &__slide {
        color: $gray-700;
    }
}
