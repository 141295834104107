.content-twocolumns {
    margin: 0 0 38px;
    display: flex;
    flex-direction: column-reverse;
    color: $gray-800;

    @include media('≥tablet') {
        flex-direction: row;
        margin: 0 0 24px;
    }

    &__col {
        width: 100%;

        &:last-child {
            margin-bottom: 64px;

            @include media('≥tablet') {
                margin-bottom: 0;
                margin-left: 24px;
                width: 343px;
                min-width: 343px;
            }
        }
    }

    h2 {
        margin: 0 0 7px;
        font-size: 24px;
        line-height: calc(32/24);
        letter-spacing: 0.03em;
        color: $gray-900;
    }

    h3 {
        margin: 0 0 8px;
        font-size: 18px;
        line-height: calc(24/18);
        letter-spacing: 0.03em;
        color: $gray-900;
    }

    p {
        margin: 0 0 17px;
    }

    ul {
        &:not([class]) {
            margin: 0 0 33px;
            padding: 0;
            list-style: none;

            >li {
                position: relative;
                padding-left: 24px;

                &:before {
                    position: absolute;
                    top: 10px;
                    left: 9px;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    content: '';
                    background: currentColor;
                }
            }
        }
    }
}

.sticky-box {
    position: sticky;
    top: 10px;
}

.sort-widget {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid $gray-400;
    box-shadow: 0 8px 16px rgba($black, .08);

    h3 {
        margin-bottom: 24px;
    }

    select {
        margin-bottom: 16px;
    }

    &__text {
        display: flex;
        justify-content: space-between;
        padding: 8px 0 28px;
        font-size: 16px;
        line-height: calc(24/16);
    }

    &__num {
        margin: 0 0 0 10px;
    }

    &__togler {
        margin: 0 0 23px;
    }

    &__price {
        padding: 7px 0 32px;
        font-size: 16px;
        line-height: 1.5;
        font-weight: $medium;
        letter-spacing: 0.03em;
    }

    &__price-new {
        color: $red;
        margin-right: 8px;
    }

    &__price-old {
        color: $gray-500;
        text-decoration: line-through;
    }

    .btn {
        display: block;
        width: 100%;
        padding: 15px;
    }
}

.address-product {
    margin: 0 0 17px;
    padding: 0;
    font-size: 14px;
    line-height: calc(24/14);
    color: $gray-700;
}

.contacts-wrap {
    padding: 16px;
    color: $gray-900;
    background: $gray-200;
    border-radius: 6px;
}

.list-contacts {
    margin: 0 0 24px;
    padding: 0;
    list-style: none;

    >li {
        margin: 0 0 8px;
    }

    a {
        color: inherit;

        &:hover {
            color: $violet;
        }
    }

    [class*="icon-"] {
        margin: 0 5px 0 0;
    }
}

.gallery-images {
    margin: -4px -4px 60px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    @include media('≥tablet') {
        margin: -4px -4px 20px;
    }

    >li {
        padding: 4px;
    }

    &__visual {
        display: block;
        width: 336px;
        height: 160px;
        overflow: hidden;
        border-radius: 8px;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__video {
        position: relative;

        &:after {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '\e920';
            font-size: 24px;
            line-height: 1;
            color: $white;
            font-family: $icons;
            background: rgba($gray-900, .5);
            opacity: 0.8;
        }
    }
}

.panel-label {
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link-button {
    display: block;
    padding: 2px 6px;
    color: $violet;
    font-weight: $medium;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    border: 2px solid $violet;
}
