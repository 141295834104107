@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.select-custom {
    appearance: none;
    padding: 8px 16px;
    width: 90px;
    height: 40px;
    display: flex;
    align-items: center;
    color: $gray-700;
    font-size: 16px;
    line-height: 24px;
    box-shadow: $shadow-01;
    border-radius: 99px;
    border: 0;
    background-color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 7' style='enable-background:new 0 0 14 7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23717171;%7D%0A%3C/style%3E%3Cpath class='st0' d='M7,6.4c-0.6,0-1.1-0.2-1.5-0.6L1.2,1.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l4.3,4.3C6.8,5,7.2,5,7.4,4.8 l4.3-4.3c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L8.5,5.8C8.1,6.2,7.6,6.4,7,6.4z'/%3E%3C/svg%3E");
    background-position: calc(100% - 5px) 50%;
    background-size: 13px auto;
    background-repeat: no-repeat;

    @include media('≥tablet') {
        background-position: calc(100% - 18px) 50%;
        width: 150px;
        padding: 8px 35px 8px 16px;
    }
}

// .custom-select-item {
//     appearance: none;
//     padding: 15px 16px;
//     width: 100%;
//     height: 56px;
//     color: $gray-900;
//     font-size: 16px;
//     line-height: 24px;
//     border-radius: 16px;
//     border: 1px solid $gray-600;
//     background-color: $white;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 7' style='enable-background:new 0 0 14 7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23717171;%7D%0A%3C/style%3E%3Cpath class='st0' d='M7,6.4c-0.6,0-1.1-0.2-1.5-0.6L1.2,1.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l4.3,4.3C6.8,5,7.2,5,7.4,4.8 l4.3-4.3c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L8.5,5.8C8.1,6.2,7.6,6.4,7,6.4z'/%3E%3C/svg%3E");
//     background-position: calc(100% - 18px) 50%;
//     background-size: 15px auto;
//     background-repeat: no-repeat;
// }

.select-custom-2 {
    appearance: none;
    padding: 15px 16px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    color: $gray-900;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid $gray-400;
    border-radius: 16px;
    background-color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 14 7' style='enable-background:new 0 0 14 7;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23717171;%7D%0A%3C/style%3E%3Cpath class='st0' d='M7,6.4c-0.6,0-1.1-0.2-1.5-0.6L1.2,1.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l4.3,4.3C6.8,5,7.2,5,7.4,4.8 l4.3-4.3c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L8.5,5.8C8.1,6.2,7.6,6.4,7,6.4z'/%3E%3C/svg%3E");
    background-position: calc(100% - 18px) 50%;
    background-size: 13px auto;
    background-repeat: no-repeat;
}
