@use '../abstracts/variables' as *;

.card-gallery {
    position: relative;
    width: 100%;

    &__like {
        position: absolute;
        top: 18px;
        right: 17px;
        z-index: 10;
        font-size: 22px;
        line-height: 1;

        .path1 {
            &:before {
                color: $gray-700;
                transition: color $animation-speed;
            }
        }

        .path2 {
            &:before {
                color: $white;
            }
        }

        &:hover {
            .path1 {
                &:before {
                    color: $violet;
                }
            }
        }
    }

    &__play {
        position: absolute;
        top: 18px;
        right: 17px;
        z-index: 10;
        font-size: 22px;
        line-height: 1;

        .path2 {
            &:before {
                color: $white;
            }
        }
     }

    &__popular {
        position: absolute;
        bottom: 24px;
        left: 0;
        z-index: 10;
        padding: 4px 8px;
        color: $gray-900;
        font-size: 14px;
        line-height: calc(16/14);
        letter-spacing: 0.03em;
        border-radius: 0 4px 4px 0;
        background: $white;
    }

    &__product-reservation {
        position: absolute;
        bottom: 24px;
        left: 0;
        z-index: 10;
        padding: 4px 8px;
        color: $white;
        font-size: 14px;
        line-height: calc(16/14);
        letter-spacing: 0.03em;
        border-radius: 0 4px 4px 0;
        background: $violet;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 9px 0 3px 0;
    }

    h3 {
        margin: 0 10px 5px 0;
        font-size: 16px;
        line-height: calc(24/16);
        letter-spacing: 0.03em;
        font-weight: $medium;
        color: $gray-900;

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    &__users {
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: $normal;
    }

    &__users-ico {
        margin: 0 5px 0 0;
    }

    &__row {
        font-size: 14px;
        line-height: calc(16/14);
        margin: 0 0 8px;
        color: $gray-700;
        letter-spacing: 0.03em;

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    &__location {
        margin: 0 17px 0 0;
    }

    &__location-ico {
        margin: -2px 5px 0 0;
        display: inline-block;
    }

    &__price {
        padding-bottom: 8px;
        font-size: 16px;
        line-height: calc(24/16);
        font-weight: $medium;
        letter-spacing: 0.03em;
    }

    &__price-new {
        color: $red;
        margin-right: 8px;
    }

    &__price-old {
        color: $gray-500;
        text-decoration: line-through;
        margin-right: 8px;
    }

    &__sale {
        color: $gray-900;
    }

    &:hover {
        .card-gallery__card-slider {
            .card-gallery__swiper-button {
                opacity: 1;
            }
        }
    }
}

.card-active {
    .path1 {
        &:before {
            color: $violet;
        }
    }
}
