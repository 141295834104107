@use '../abstracts/variables' as *;

.list-step {
    margin: 0 -5px 23px;
    padding: 0;
    list-style: none;
    display: flex;

    >li {
        width: 25%;
        padding: 0 5px;
    }

    .active {
        .step-item {
            &__num {
                font-size: 14px;

                &:after {
                    display: none;
                }
            }
        }

        ~li {
            .step-item {
                &__num {
                    background: $gray-600;
                    color: $gray-900;
                    font-size: 14px;

                    &:after {
                        display: none;
                    }

                    &:before {
                        background: linear-gradient(to right, $gray-600 0%, $gray-600 16px, transparent 16px, transparent 56px, $gray-600 56px, $gray-600 100%);
                    }
                }

                &__text {
                    color: $gray-700;
                }
            }
        }
    }
}

.step-item {
    display: block;
    margin: 0 auto;
    max-width: 72px;
    text-align: center;

    &__num {
        position: relative;
        margin: 0 auto 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 0;
        font-weight: $bold;
        border-radius: 12px;
        background: $violet;
        color: $white;

        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 8px;
            line-height: 1;
            content: '\e904';
            font-family: $icons;
        }

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 72px;
            height: 2px;
            content: '';
            background: linear-gradient(to right, $violet 0%, $violet 16px, transparent 16px, transparent 56px, $violet 56px, $violet 100%);
        }
    }

    &__text {
        color: $gray-700;
        color: $gray-900;
        font-size: 14px;
        line-height: calc(24/14);
    }
}
