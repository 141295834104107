@use '../abstracts/variables' as *;

.tabset-profile {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 24px;

    >li {
        width: 100%;
        border-bottom: 1px solid transparent;
        text-align: center;
    }

    .active {
        border-color: $gray-900;

        a {
            color: $gray-900;
        }
    }

    a {
        padding: 0 10px 6px;
        color: $gray-800;
        display: block;
    }
}

.tab-holder {
    padding: 23px 0 0;
}

.email_edit_box {
    position: relative;

    .email_edit_box__button {
        position: absolute;
        right: 15px;
        top: 17px;
        font-size: 24px;
        line-height: 1;
        color: $gray-900;
        transition: color $animation-speed;

        &:hover {
            color: $violet;
        }
    }

    input[type="email"] {
        pointer-events: none;
    }

    &.email-succes,
    &.email-edit {
        .email_edit_box__button {
            .icon-edit {
                &:before {
                    content: '\e92a';
                }
            }
        }

        input[type="email"] {
            pointer-events: initial;
        }
    }

    &.email-succes {
        input[type="email"] {
            border-color: $green;
        }

        .text-succes {
            position: relative;
            display: block;
            margin-top: -22px;
        }
    }
}
