@use '../abstracts/variables' as *;

.opener-menu {
    padding: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    color: $gray-700;
    font-size: 24px;
    line-height: 1;
    box-shadow: $shadow-01;
    border-radius: 99px;
    transition: background $animation-speed;

    .menu-active & {
        background: $gray-200;
    }

    &__ico {
        display: block;
        width: 24px;
        height: 24px;
        margin: 0 4px;
    }
}
