@use '../include-media/include-media' as *;

.map {
    margin: 0 0 33px;

    @include media('≥tablet') {
        margin: 0 0 53px;
    }

    h2 {
        margin: 0 0 23px;
        font-size: 24px;
        line-height: calc(32/24);
    }

    &__wrap {
        position: relative;
        height: 400px;
        overflow: hidden;
        border-radius: 16px;

        iframe {
            position: absolute;
            top: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}
