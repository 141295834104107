.activate-card {
    padding: 9px 0;
    color: $gray-900;

    @include media('≥tablet') {
        padding: 15px 15px 9px;
        margin-bottom: 24px;
        border-radius: 16px;
        border: 1px solid $gray-400;
    }

    h2 {
        margin-bottom: 15px;

        @include media('≥tablet') {
            margin-bottom: 24px;
        }
    }

    &__input {
        position: relative;
    }

    input {
        margin-bottom: 16px;
        padding-right: 50px;
    }

    button {
        margin: 0;
        height: 52px;
        padding: 0;
        position: absolute;
        right: 16px;
        top: 2px;
        border: 0;
        font-size: 24px;
        line-height: 1;
        background: transparent;

        &:hover {
            background: transparent;
            color: $violet;
        }
    }

    .input-succes+.text-succes,
    .input-error+.text-succes+.text-error {
        margin-top: -16px;
    }
}