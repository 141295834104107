.list-play {
    margin: 0;
    padding: 0 8px;
    list-style: none;

    >li {
        +li {
            margin-top: 16px;
        }
    }
}

.item-play {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    color: $gray-900;

    &__visual {
        position: relative;
        width: 88px;
        min-width: 88px;
        height: 64px;
        border-radius: 16px;
        overflow: hidden;
        margin: 0 16px 0 0;

        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            content: '\e920';
            font-size: 18px;
            line-height: 1;
            color: $white;
            font-family: $icons;
        }

        img {
            display: block;
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    &__text {
        flex-grow: 1;
    }
}