@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.grid-post {
    margin: 0 0 27px;
    padding: 4px 0 0;
    list-style: none;

    >li {
        +li {
            margin-top: 24px;
        }
    }
}

.vse-o-nakupu {
    @include media('≥600px') {
        display: flex;
    }

    @include media('≥tablet') {
        display: block;
    }

    @include media('≥desktop') {
        display: flex;
    }

    &__visual {
        display: block;
        overflow: hidden;
        min-height: 200px;
        width: 100%;
        margin: 0 0 24px;
        border-radius: 16px;

        @include media('≥600px') {
            min-height: 200px;
            width: 42.7%;
            min-width: 42.7%;
            margin: 0 24px 0 0;
            border-radius: 16px 0 0 16px;
        }

        @include media('≥tablet') {
            width: 100%;
            margin: 0 0 24px;
            border-radius: 16px;
        }

        @include media('≥desktop') {
            min-height: 200px;
            width: 42.7%;
            min-width: 42.7%;
            margin: 0 24px 0 0;
            border-radius: 16px 0 0 16px;
        }

        img {
            display: block;
            max-width: none;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform: scale(1);
            transition: transform $animation-speed;
        }
    }

    &__body {
        align-self: center;

        @include media('≥600px') {
            padding: 10px 0;
        }

        @include media('≥tablet') {
            padding: 0;
        }

        @include media('≥desktop') {
            padding: 10px 0;
        }
    }

    &__content {
        p {
            margin: 20px 0;
        }

        li {
            padding: 10px 0;
        }

        h3 {
            margin: 20px 0;
        }

        span {
            font-weight: 700;
            font-style: italic;
            margin: 0 -20px;
        }
    }

    &__name {
        display: block;
        margin: 0 0 9px;
        font-weight: $medium;
        font-size: 12px;
        line-height: 16px;
        color: $gray-550;
    }

    h2 {
        margin: 20px 0;

        a {
            color: inherit;

            &:hover {
                color: $violet;
            }
        }
    }

    a.card-post__visual {
        &:hover {
            img {
                transform: scale(1.05);
            }
        }
    }

    &__sub-list {
        padding-left: 0;

        li {
            margin: 20px 0;

            a {
                display: inline-block;
            }
        }

        .custom_prubeh {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "✔"; /* Unicode character */
                margin-right: 10px;
                color: $violet; /* Optional: color the symbol */
            }
        }

        .custom_vyuziti {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "★"; /* Unicode character */
                margin-right: 10px;
                color: $green; /* Optional: color the symbol */
            }
        }

        .custom_rezervace {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "🌞"; /* Unicode character */
                margin-right: 10px;
                color: $red; /* Optional: color the symbol */
            }
        }

        .custom_storno {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "⛈"; /* Unicode character */
                margin-right: 10px;
                color: $blue; /* Optional: color the symbol */
            }
        }

        .custom_sleva {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "😂"; /* Unicode character */
                margin-right: 10px;
                color: $yellow; /* Optional: color the symbol */
            }
        }

        .custom_zazitek {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "👍"; /* Unicode character */
                margin-right: 10px;
                //color: $violet; /* Optional: color the symbol */
            }
        }

        .custom_podpora {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 1em;

            &::before {
                content: "📞"; /* Unicode character */
                margin-right: 10px;
                //color: $violet; /* Optional: color the symbol */
            }
        }
    }
}

.tag-list {
    margin: -2px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    font-weight: $medium;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;

    >li {
        padding: 2px;
    }

    a {
        padding: 2px 6px;
        color: $violet;
        display: block;
        border: 2px solid $violet;
        border-radius: 4px;
        transition: all $animation-speed;

        &:hover {
            background: $violet;
            color: $white;
        }
    }
}
