@use '../abstracts/variables' as *;

.package {
    padding: 24px 0 40px;
    border-top: 1px solid $gray-600;
    border-bottom: 1px solid $gray-600;

    &--add {
        padding-bottom: 18px;
        border-bottom: 0;
    }
}

.package-list {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        margin-bottom: 24px;
    }
}

.package-tab-opener {
    position: absolute;
    opacity: 0;
    visibility: inherit;

    &:checked {
        +label {
            &:before {
                border-width: 6px;
                border-color: $gray-900;
            }
        }
    }
}

.contact-informations {
    padding: 16px;
    background: $gray-200;
    border-radius: 6px;
}

.contact-info {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        padding-left: 32px;

        +li {
            margin: 16px 0 0;
        }
    }

    p {
        margin: 0;
    }

    a {
        color: inherit;

        &:hover {
            color: $violet;
        }
    }
}

.package-checkbox-2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 15px 50px 15px 16px;
    width: 100%;
    height: 56px;
    color: $gray-900;
    font-size: 16px;
    line-height: 24px;
    border-radius: 16px;
    border: 1px solid $gray-600;
    background-color: $white;

    &:before {
        content: '';
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid $gray-600;
        transition: border $animation-speed;
    }
}

.package-slide {
    display: none;
    margin-bottom: 16px;

    &.active {
        display: block;
    }
}

.receiver-information {
    input {
        margin-bottom: 24px;
    }
}

.payment {
    padding-bottom: 40px;
    border-bottom: 1px solid $gray-600;
}

.payment-list {
    margin: 0;
    padding: 0;
    list-style: none;

    >li {
        margin-bottom: 24px;
    }
}
