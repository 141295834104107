@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.card-slider {
    width: 100%;

    &__visual {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;
        border-radius: 16px;
        background: $gray-600;
        font-size: 0;
        line-height: 0;

        img {
            position: absolute;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            object-fit: cover;
            object-position: 50% 50%;
        }
    }

    .swiper-lazy-preloader {
        position: absolute;
        inset: 0;
        background: $gray-500 url('../images/loader.gif') no-repeat;
        background-position: 50% 50%;
    }

    &__swiper-pagination {
        .swiper-pagination-bullet {
            width: 20px;
            height: 20px;
            background: $white;

            @include media('≥tablet') {
                width: 8px;
                height: 8px;
            }
        }
    }
}

.swiper-button {
    z-index: 10;
    opacity: 0;
    width: 32px;
    height: 32px;
    background: rgba($white, .6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 1;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    transition: opacity $animation-speed;

    &:after {
        display: none;
    }

    &.swiper-button-disabled {
        opacity: 0;
    }
}
