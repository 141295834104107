@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.filter-opener {
    padding: 8px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    color: $gray-700;
    font-size: 24px;
    line-height: 24px;
    border-radius: 99px;
    border: 1px solid $gray-400;

    @include media('≥tablet') {
        padding: 8px 16px;
        width: auto;
        border: 0;
        box-shadow: $shadow-01;
    }

    &__text {
        display: none;

        @include media('≥tablet') {
            margin: 0 8px 0 0;
            display: block;
            font-size: 16px;
            font-weight: $normal;
        }
    }

    &__ico {}
}
