.sort-panel {
    margin: 0 -16px 16px;
    padding-top: 8px;

    @include media('≥tablet') {
        padding-top: 0;
        margin: 0 0 8px;
    }
}

.sort-list {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 16px;
    line-height: calc(20/16);
    font-weight: $semibold;
    letter-spacing: 0.02em;
    overflow: auto;

    >li {
        padding: 0 16px 14px;
        position: relative;
    }

    a {
        color: $gray-700;

        &:after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            visibility: hidden;
            height: 3px;
            content: '';
            background: $gray-900;
            transform: translateX(-50%);
            transition: all $animation-speed;
        }

        &:hover {
            &:after {
                width: 100%;
                visibility: visible;
            }
        }
    }

    .active {
        a {
            color: $gray-900;

            &:after {
                width: 100%;
                visibility: visible;
            }
        }
    }
}