@use '../abstracts/variables' as *;
@use '../include-media/include-media' as *;

.gallery-wrap-container {
    overflow: hidden;
}

.gallery-cards {
    padding: 0 0 47px;
    margin: 0 0 40px;
    border-bottom: 1px solid $gray-100;

    &:last-child {
        border: 0;
        margin-bottom: 0;
        padding-bottom: 16px;
    }

    h2 {
        margin: 0 0 23px;
        padding-right: 70px;
        font-size: 24px;
        line-height: calc(32/24);
    }
}

.gallery {
    position: relative;
    overflow: visible;
    padding: 0 29% 0 0;

    @include media('≥phone') {
        padding: 0 23px 0 0;
    }

    &__button {
        width: 40px;
        height: 40px;
        border-radius: 8;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 10;
        top: -64px;
        right: 0;
        font-size: 18px;
        line-height: 1;
        color: $gray-700;
        cursor: pointer;

        &.swiper-button-prev-main {
            right: 42px;
            left: auto;
        }

        &:hover {
            background: $gray-200;
            color: $gray-900;
        }
    }
}
