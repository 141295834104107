#admin-bar {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 32px;
    padding: 6px 20px;
    color: $white;
    background: $gray-900;

    p {
        margin: 0;
    }

    +#header {
        margin-top: 32px;
    }
}