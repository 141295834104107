.searched-post {
    h2 {
        margin: 0 10px 5px 0;
        line-height: 1.5;
        letter-spacing: 0.03em;
        color: $gray-900;

        a {
            color: inherit;
        }
    }

    &__body {

    }

    &__content {

    }

    &__name {
        color: inherit;
        padding: 10px 0;
        display: block;
        font-size: 14px;
    }
}
