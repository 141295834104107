@use '../abstracts/variables' as *;

.lang-dropdown {
    position: relative;
    display: inline-block;
}

.lang-menu {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    background: $white;
    list-style: none;
    padding: 16px 0;
    margin: 0;
    border-radius: 16px;
    box-shadow: 0 0 4px rgba($black, 0.04), 0 4px 8px rgba($black, 0.06);
    display: none;
    z-index: 99;
    font-weight: $normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    transition: all $animation-speed;
}

.lang-menu li {
    display: block;
}

.lang-menu a {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    font-size: 14px;
    text-decoration: none;
    color: $gray-900;
    transition: background 0.2s ease;
}

/* Show menu when dropdown is active */
.lang-dropdown.active .lang-menu {
    display: block;
}

.multi-language {
    padding: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-700;
    font-size: 24px;
    line-height: 1;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 50%;

    &:hover {
        color: $black;
        text-decoration: none;
        cursor: pointer;
    }
}
