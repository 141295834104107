// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.btn {
    border: $btn-border;
    border-radius: $btn-border-radius;
    display: inline-block;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    padding: $btn-padding;
    vertical-align: top;
    text-align: center;
    font-weight: $medium;
    transition: all $animation-speed;
    color: $btn-color;
    background: $btn-background;
    height: auto;

    &:hover {
        background: $btn-hover-background;
        border: $btn-border;
        color: $btn-hover-color;
    }

    &--primary {
        background: $violet;
        color: $white;
        border-color: $violet;

        &:hover {
            color: $white;
            background: $violet-500;
            border-color: $violet-500;
        }

        &:focus {
            color: $white;
        }
    }

    &--lg {
        padding: 15px;
    }

    &--sm {
        padding: 7px 16px;
    }
}