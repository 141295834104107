.category_box {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid $gray-400;

    @include media('≥tablet') {
        padding: 0;
        border: 0;
        border-radius: 0;
    }

    &__opener {
        display: block;
        position: relative;

        @include media('≥tablet') {
            display: none;
        }

        &:after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '\e908';
            font-size: 8px;
            line-height: 1;
            font-family: $icons;
        }
    }

    &.active {
        .category_box__opener {
            &:after {
                content: '\e909';
            }
        }
    }
}

.categorie-list {
    margin: 0;
    padding: 12px 0;
    list-style: none;
    font-size: 16px;
    line-height: 24px;

    @include media('≥tablet') {
        padding: 12px 8px;
    }

    >li {
        +li {
            margin-top: 16px;

            @include media('≥desktop') {
                margin-top: 24px;
            }
        }
    }

    a {
        display: flex;
        justify-content: space-between;
        color: $gray-900;

        &:hover {
            color: $violet;
        }
    }
}