.category-list-scroll {
    margin: 0 -16px;
}

.category-list {
    margin: 0 0 28px;
    padding: 10px 0;
    list-style: none;
    display: flex;
    overflow: auto;

    >li {
        padding: 0 12px;
    }
}

.category-item {
    display: block;
    width: 88px;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: $gray-900;

    &:hover {
        .category-item__avatar {
            img {
                box-shadow: 0 0 8px rgba($violet, .5);
            }
        }
    }

    &__avatar {
        position: relative;
        width: 88px;
        height: 88px;
        margin: 0 0 5px;

        img {
            max-width: none;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            object-position: 50% 50%;
            box-shadow: 0 0 0 rgba($violet, .4);
            transition: box-shadow $animation-speed;
        }
    }

    &__number {
        padding: 1px 4px;
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 1;
        color: $violet;
        background: $white;
        border: 1px solid $violet;
        border-radius: 99px;
    }
}